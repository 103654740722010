//import logo from './logo.svg';
/* import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
 */

import './App.css';
import React, { useEffect } from 'react';
import { ImageBackground , ScrollView , StyleSheet, CheckBox, Text, View, Dimensions, Image, FlatList, TouchableWithoutFeedback, TextInput, Alert, Platform} from 'react-native';
import { Modal, Linking } from 'react-native';
//import {io} from 'socket.io-client';
import { useMediaQuery } from 'usehooks-ts';


import bitcoindigits_svg from './img/bitcoindigits.svg';
import lobtcdgtsgo_svg from './img/btcdgts-parallax.svg';
import discord_icon_svg from './img/discord-icon.svg';
import twitter_icon_svg from './img/twitter-icon.svg';
import meden_icon from './img/Magic-Eden-trans.png';
import okx_icon  from './img/okx_trans.png';


function Link(props) {
  return <Text {...props} accessibilityRole="link" style={StyleSheet.compose(styles.link, props.style)} />;
}



const apiurl = 'https://api.12percent.hu/apiordinal/';

let ScreenHeight = Dimensions.get('window').height;
let ScreenWidth = Dimensions.get('window').width;

//render items

function MouseEnter(event) {
  
  //event.target.style.borderWidth = 2;
  //event.target.style.borderColor = '#F1F1F1';
  //event.target.style.background = '#F1F1F1';
  //event.target.firstChild.style.background = '#F1F1F1';
  //event.target.style.color = '#252525';
  //event.target.firstChild.style.color="#252525";
  
}
function onMouseLeave(event){

  //event.target.style.borderWidth = 0;
  //event.target.style.borderColor = '#F1F1F1';
  //event.target.style.background="#000";
  //event.target.firstChild.style.background="#000";
  //event.target.style.color = '#FFFFFF';
  //event.target.firstChild.style.color="#FFFFFF";
  
}
/* 
function MouseEnterBtn(event) {
  event.target.style.background = '#000';
  event.target.firstChild.style.background = '#000';
  event.target.style.color = '#696969';
  event.target.firstChild.style.color="#696969";
  event.target.style.borderColor = '#696969';
}
function onMouseLeaveBtn(event){
  event.target.style.background = "#000";
  event.target.firstChild.style.background = "#000";
  event.target.style.color = '#FFF';
  event.target.firstChild.style.color="#FFF";
  event.target.style.borderColor = '#FFF';

}
  */


function App() {

  const isDesktopLandscape = useMediaQuery('(min-width: 768px)');


  const [loading, setLoading] = React.useState(true);
  const [collectionType, setCollectionType] = React.useState(1);
  const [collectionIsPrime, setCollectionIsPrime] = React.useState(0);
  const [collectionIsFibo, setCollectionIsFibo] = React.useState(0);
  const [collectionIsPoker, setCollectionIsPoker] = React.useState(0);
  const [collectionIsPalindrome, setCollectionIsPalindrome] = React.useState(0);
  const [numbers, setNumbers] = React.useState([]);
  const [offset, setOffset] = React.useState(1);
  const [hasMoreItemToLoad, setHasMoreItemToLoad] = React.useState(true);
  
  const [searchDataView, setSearchDataView] = React.useState('');
  const [searchAdmin, setSearchAdmin] = React.useState('');
  

  const [filterType, setFilterType] = React.useState('All');
  const [sortType, setSortType] = React.useState('Asc');
  const [subType, setSubType] = React.useState('All');
  const [filteredItem, setFilteredItem] = React.useState('');
  
  const [yHeld, setYHeld] = React.useState(false);
  const [xHeld, setXHeld] = React.useState(false);
  const [controlHeld, setControlHeld] = React.useState(false);
   
  const [approveDropdownVisible, setApproveDropdownVisible] = React.useState(false);
  const [ordinalDropdownVisible, setOrdinalDropdownVisible] = React.useState(false);

  const [ordinalSelectOptions, setOrdinalSelectOptions] = React.useState([]);
  const [ordinalSelectType, setOrdinalSelectType] = React.useState(-1);
  const [searchOrdinalSelect, setSearchOrdinalSelect] = React.useState('');
  
  const [openAdminPass, setOpenAdminPass] = React.useState(false);
  const [adminPass, setAdminPass] = React.useState('');
  const [adminPassIamHuman, setAdminPassIamHuman] = React.useState(false);


  const [confirmRegistrationSaveVisible, setConfirmRegistrationSaveVisible] = React.useState(false);
  const [registrationIamHuman, setRegistrationIamHuman] = React.useState(false);

  const [confirmAdminSaveVisible, setConfirmAdminSaveVisible] = React.useState(false);
  const [adminSaveIamHuman, setAdminSaveIamHuman] = React.useState(false);
 
  const [confirmAdminDeleteVisible, setConfirmAdminDeleteVisible] = React.useState(false);
  const [adminDeleteIamHuman, setAdminDeleteIamHuman] = React.useState(false);

  const [adminReadOrdsVisible, setAdminReadOrdsVisible] = React.useState(false);
  



  const [selectedItemForEdit, setSelectedItemForEdit] = React.useState('');
  const [selectedItemForDelete, setSelectedItemForDelete] = React.useState('');
  
  const [clickLocationX, setClickLocationX] = React.useState(0);
  const [clickLocationY, setClickLocationY] = React.useState(0);

  const [selectedItemForDataView, setSelectedItemForDataView] = React.useState('');
  const [selectedItemForRegistration, setSelectedItemForRegistration] = React.useState('');
  
  const [itemData, setItemData] = React.useState([]);
  
  const [isAboutVisible, setAboutVisible] = React.useState(false);
  const [isFaqVisible, setFaqVisible] = React.useState(false);

  const [isAdminVisible, setAdminVisible] = React.useState(false);
  const [itemAdminData, setItemAdminData] = React.useState([]);
  const [forceReloadAdmin, setForceReloadAdmin] = React.useState(false);
    
  const [itemOrdinalData, setItemOrdinalData] = React.useState([]);
  const [itemOrdinalDataButton, setItemOrdinalDataButton] = React.useState('Read Digits');
  const [ordinalDataFrom, setOrdinalDataFrom] = React.useState('101');
  const [ordinalDataTo, setOrdinalDataTo] = React.useState('801');
  const [searchOrdinalData, setSearchOrdinalData] = React.useState('');
  const [searchOrdinalDataOnlyDigits, setSearchOrdinalDataOnlyDigits] = React.useState(true);

  const [isRegistrationPanelVisible, setRegistrationPanelVisible] = React.useState(false);
  const [isDataViewPanelVisible, setDataViewPanelVisible] = React.useState(false);
  
  //save
  const [saveOrdId, setSaveOrdId] = React.useState('');
  const [saveAddress, setSaveAddress] = React.useState('');
  const [saveTransId, setSaveTransId] = React.useState('');
  const [saveTwitterId, setSaveTwitterId] = React.useState('');
  const [saveDiscordId, setSaveDiscordId] = React.useState('');
  const [savetimestamp, setSavetimestamp] = React.useState('');

  const ordDataRef = React.useRef([]);

  //5digit
  const runCounter = React.useRef(400000);
  const runBeny = React.useRef([]);
  const runCounterBeny = React.useRef(0);
  const [runMax, setRunMax] = React.useState(1000000);
  

  /*-------------------------------------------------------------------------------*/
/*   
  const trac = io("https://bitmap.trac.network", {
      autoConnect : true,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionDelayMax : 500,
      randomizationFactor : 0
  });

  trac.connect();
  
  trac.on('response', async function(msg){
    
    if(msg.result.content_type.startsWith('image'))
    {
        //document.getElementById('number_result').innerHTML =
          //  '<div><img style="max-width: 300px;" src="data:'+msg.result.content_type+';base64,'+msg.result.content+'"></div>';
    }
    else if(msg.result.content_type.startsWith('text/plain'))
    {
      
        let content= decodeURIComponent(escape(atob(msg.result.content)));
        if (content.length==5) {
          try {
            if (!isNaN(parseInt(content)))
            {
              if (parseInt(content) == content) {
                save5digitToDb(msg.result);
              }
            }
          } catch (e) {
              //return false;
          }
        }

    }
    else
    {
        //document.getElementById('number_result').innerText = '';
    }
    

    runCounterBeny.current = runCounterBeny.current + 1;
    getNumInscribedBeny();
  });

  // default error event for internal Trac errors, if any

  trac.on('error', async function(msg){
      console.log("ERRRRRR", msg);
  });

  function startGetNumInscribed() {
    runCounter.current = parseInt(ordinalDataFrom);
    getNumInscribed();
  }
  
  let cc = 1
  
  function getNumInscribed() //async
        {
        setTimeout(function() {  
          if (((runCounter.current + 1) * 60) < parseInt(ordinalDataTo)) {
            let number= runCounter.current * 60;
            let numberTo= (runCounter.current + 1) * 60;
            setItemOrdinalDataButton('Reading ' + number + ' / ' + numberTo + ' / ' +  ordinalDataTo);
            if(isNaN(parseInt(number)))
            {
                if(!number.endsWith('i0'))
                {
                    alert('Please enter a valid inscription number or id.');
                    return;
                }
            }
           // console.log(number);
            
            fetch(`https://api.hiro.so/ordinals/v1/inscriptions?from_number=${number}&to_number=${numberTo}&mime_type=text%2Fplain&limit=60`, { //https://api.hiro.so/ordinals/v1/inscriptions/${number}/content
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            })
            .then((response) => response.text())
            .then((text) => {
              
                try {
                    
                    const jsonData = JSON.parse(text); 
                    if (!jsonData.error) {
                        runBeny.current = [];
                        console.log(jsonData.results);
                        jsonData.results.map((upditem, index) => {
                          
                              if (parseInt(upditem.content_length)<6) {
                                runBeny.current.push(upditem.number);
                              }
                                   
                        })
                        
                       // console.log("WWW", json);

                        if (runBeny.current.length>0) {
                          runCounterBeny.current = 1;
                          getNumInscribedBeny();
                          return;
                        }

                    } else {
                    }
                    

                } catch(err) {
                    // The response wasn't a JSON object
                    console.log(err);
                    console.log(text);
                  }
                  
                })
                .catch((error) => {
                    console.log(error);
                });

                runCounter.current = runCounter.current + 1;
                getNumInscribed();

          } else {
            setItemOrdinalDataButton('Read Digits');
          }

          

        }, 1000)
       }


  function getNumInscribedBeny() //async
        {
        setTimeout(function() {  
          
          if (runCounterBeny.current < runBeny.current.length) {
            
            let number= runBeny.current[runCounterBeny.current-1];
      //      setItemOrdinalDataButton('Reading ' + number + ' / ' +  ordinalDataTo);
            if(isNaN(parseInt(number)))
            {
                if(!number.endsWith('i0'))
                {
                    alert('Please enter a valid inscription number or id.');
                    return;
                }
            }
            console.log(number);
            trac.emit('get',
            {
                func : 'ordinal', //ownerOf
                args : [number],
                call_id : ''
            });

            // trac.emit('get',
              //  {
                //    func : 'ordinal',
               //     args : [number],
               //     call_id : ''
              //  }); 
          } else {
         //   setItemOrdinalDataButton('Read Digits');
              getNumInscribed();
          }
        }, 1000)
       }

function save5digitToDb(jsondata) {

  let formData = new FormData();


  formData.append('ordinal_id', jsondata.id);
  formData.append('num',decodeURIComponent(escape(atob(jsondata.content))) );
  formData.append('ordinal_rank', jsondata.number);
  formData.append('ord_address', "");
  formData.append('trans', "");
  formData.append('sat', "");
  formData.append('tstamp', jsondata.timestamp);
  formData.append('ord_type', jsondata.content_type);
  
  var url = apiurl + 'saveBennyToDb';

    fetch(url, {
      method: 'POST',
      headers: {
          Accept: 'application/json'
      },
      body: formData
    })
      .then((response) => {
        console.log(response);
          if (!response.ok) {
              throw new Error("Not authorized")
          }
          return response.json();
      })
        .then(json => {
          console.log(json);
            if(json.error) {
              
            } else {
              
              
            }
          })
          .catch((error) => {
            
          });
} */

/* -------------------------------------------------------------------------------- */
//Admin

//selected item get
useEffect(() => {
  (async () => {
      if (isAdminVisible) {
        loadItemAdminData();
      }

  })();

}, [isAdminVisible, forceReloadAdmin]); 



useEffect(() => {
  (async () => {
      if (isAdminVisible) {
        loadOrdinalSelectorData();
     }
  })();

}, [ordinalSelectType]); 


const flatlistAdminRef  = React.useRef();


function sendAdminVerification() {

  if (!adminPassIamHuman) {
    alert("Please check Robot check");
    return;
  }

  try{
    
    var url = apiurl + 'verification?pass=' + adminPass  ;
    //console.log('Retrieved item from database...', url);
    fetch(url, {
        
        method: 'POST',
        headers: {
            'Accept': 'application/json'
        }
    })
        .then((response) => response.json())
        .then((json) => {
          //console.log(json);
            if (json.pass==='ok') {
              setAdminVisible(true);
              setOpenAdminPass(false);
              setAdminPassIamHuman(false);
            } else {
              alert('Invalid password');
            }
        })
        .catch((error) => {
          //console.log(error);
        })
} catch (e) {
  //console.log(e);
}

}



function loadItemAdminData() {
        
  try{

      

      var url = apiurl + 'getItemAdminData?itemID=' ;
      //console.log('Retrieved item from database...', url);
      fetch(url, {
          
          method: 'GET',
          headers: {
              'Accept': 'application/json'
          }
      })
          .then((response) => response.json())
          .then((json) => {
              setItemAdminData(json.data)
              //console.log(json.data);
          })
          .catch((error) => {
            //console.log(error);
          })
  } catch (e) {
    //console.log(e);
  }

}

function loadOrdinalSelectorData() {
        
  try{

      

      var url = apiurl + 'getOrdinalSelectorData?collID=' + ordinalSelectType ;
      //console.log('Retrieved item from database...', url);
      fetch(url, {
          
          method: 'GET',
          headers: {
              'Accept': 'application/json'
          }
      })
          .then((response) => response.json())
          .then((json) => {
              setOrdinalSelectOptions(json.data)
              //console.log(json.data);
          })
          .catch((error) => {
            //console.log(error);
          })
  } catch (e) {
    //console.log(e);
  }

}

function getNextId(obj) {
  return (Math.max.apply(Math, obj.map(function(o) {
    return o.id;
  })) + 1);
}

function addAdminData() {

  //flatlistAdminRef.scrollToOffset({ animated: true, offset: 0 });
  ////console.log(flatlistAdminRef);
  flatlistAdminRef.current.scrollToIndex({ animated: true, index: 0, viewPosition: 0 });


    let nextId=getNextId(itemAdminData);

   // const addedResult = itemAdminData.unshift({ID: nextId, itemID:'0', ordID:'' , address:'', transID:'', timestamp:'', timezone: 'UTC', rank:'0', verified:'0', inserted:'1'});
   

   var addedResult = [{id: "'" + nextId + "'", item_id: '0', item_ordinal:'' , item_address:'', item_transaction:'', item_timestamp:'', item_timestamp_timezone: 'SATOSHI', twitter: '', discord: '', item_verified:'0', uid_token: '', item_rank:'0', collection_id: '0', btc_ordinal: '', searchfield: '', cdate: '1900-01-01 00:00:00', updated: '0', inserted:'1', checkordinal: ''}];
   
   addedResult = addedResult.concat(itemAdminData);

    //console.log("EEEEE", addedResult);

    setItemAdminData(addedResult);


  

}


function checkAdminData() {

  
  let updItems = itemAdminData.filter((items) => items.updated == 1);
  
  //console.log(updItems);
  
  if (updItems && updItems.length>0) {
    for (var i=0; i < updItems.length; i++) {
      if (!checkAdminDataItem(updItems[i])) {
        flatlistAdminRef.current.scrollToItem({ animated: true, item: updItems[i], viewPosition:0 })
        return;
      }
    }
  } else {
    alert("Nothing to save");
    return;
  }

  setConfirmAdminSaveVisible(true);

}


function checkAdminDataItem(itemRow) {
 
  // let itemRow = itemAdminData.find(x => x.id == id);

   if (itemRow.item_ordinal=='') {
     alert("Please supply Ordinal Id");
     return false;
   } else if (itemRow.item_ordinal.length!=66) {
     alert("Please supply valid Ordinal Id : 66 chars");
     return false;
   } else if (itemRow.item_ordinal.slice(-2)===':0') {
     alert("Please supply valid Ordinal Id : 66 chars and not ending with :0");
     return false;
   }

   if (itemRow.item_address=='') {
     alert("Please supply address");
     return false;
   }

   if (itemRow.item_transaction=='') {
     alert("Please supply Transaction Id");
     return false;
   }

   if (itemRow.item_timestamp=='') {
     alert("Please supply Transaction timestamp");
     return false;
   } 

   if (itemRow.item_timezone=='') {
     alert("Please supply Transaction timestamp timezone");
     return false;
   }

   if (itemRow.item_rank=='') {
     alert("Please supply rank");
     return false;
   }
   
   if (itemRow.item_verified=='') {
     alert("Please supply verified");
     return false;
   }

   return true;
  

}


function deleteAdminData() {

  if (!adminDeleteIamHuman) {
      alert("Please check Robot check");
      return;
  }


  if (selectedItemForDelete.inserted=='1') { //mert meg nincs adatbazisban
    removeItemFromAdminData();
    setSelectedItemForDelete([]);
    setConfirmAdminDeleteVisible(false);
    setAdminDeleteIamHuman(false);
    return;
  }

  
    try {

      let formData = new FormData();
      formData.append('ID', selectedItemForDelete.id);

      var url = apiurl + 'removeItem'
        //console.log('delete item to database...', url);
        fetch(url, {
            
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            body: formData
        })
            .then((response) => response.json())
            .then((json) => {
                
                //console.log(json.data);
                
                if (json.error == 'false') {
                    removeItemFromAdminData();
                }
                
                setSelectedItemForDelete([]);
                setConfirmAdminDeleteVisible(false);
                setAdminDeleteIamHuman(false);


            })
            .catch((error) => {
              //console.log(error);
            })
      } catch (e) {
        //console.log(e); 
      }
    
}


function removeItemFromAdminData() {

  const removedResult = itemAdminData.filter(itemAdminData => itemAdminData.id !== selectedItemForDelete.id);
  setItemAdminData(removedResult);

}



function saveAdminData() {

  if (!adminSaveIamHuman) {
      alert("Please check Robot check");
      return;
  }

  let updItems = itemAdminData.filter((items) => items.updated == 1);

  if (updItems && updItems.length>0) {
    for (var i=0; i < updItems.length; i++) {
      saveAdminDataItem(updItems[i], (i+1)==updItems.length ? 0 : 1)
    }
  } else {
    alert("Nothing to save");
  }

}


function saveAdminDataItem(itemRow, isMass) {

//  let itemRow = itemAdminData.find(x => x.id === id);


  
try{
    
    
    let formData = new FormData();
    formData.append('ID', itemRow.id);
    formData.append('itemID', itemRow.item_id);
    formData.append('ordID', itemRow.item_ordinal);
    formData.append('address', itemRow.item_address);
    formData.append('transID', itemRow.item_transaction);
    formData.append('timestamp', itemRow.item_timestamp);
    formData.append('timezone', itemRow.item_timestamp_timezone);
    formData.append('rank', itemRow.item_rank);
    formData.append('verified', itemRow.item_verified);
    
    //var url = apiurl + 'saveItem?itemID=' + selectedItemForDataView.id + '&ordID=' + saveOrdId + "&address=" + saveAddress + "&transID=" + saveTransId + "&timestamp=" + savetimestamp + "&twitter=" + saveTwitterId + "&discord=" + saveDiscordId;

    var url = apiurl + (itemRow.inserted == '1' ? 'saveItem' : 'updateItem');
    //console.log('save item to database...', url);
    
    fetch(url, {
        
        method: 'POST',
        headers: {
            'Accept': 'application/json'
        },
        body: formData
    })
        .then((response) => response.json())
        .then((json) => {
            
            //console.log(json.data);
            //console.log(json.error);
            if (json.error == 'false') {
              //updateOrdinalAfterSave(json.data);
              //setItemAdminData(json.data);
              //cancelRegistrationPanel();
              
              if (isMass==0) {
                alert(json.message);
                setForceReloadAdmin(!forceReloadAdmin);
              }
            } else {
                alert(json.message);
            }

            setConfirmAdminSaveVisible(false);
            setAdminSaveIamHuman(false);
        })
        .catch((error) => {
          //console.log(error);
        })
  } catch (e) {
    //console.log(e);
  }


}


function getNewOrdinalNumbers() {

  
  if (searchOrdinalDataOnlyDigits) {
    var param = 'digits';
  } else {
    var param = 'all';
  }

  var url = apiurl + 'getNewOrdinals?all=' + param;
  //console.log('get items to database...', url);

  try {
      fetch(url, {
            
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    })
        .then((response) => response.json())
        .then((json) => {
            
            if (!json.error) {  
              setOrdinalDataFrom(json.data[0]);
              setOrdinalDataTo(json.data[1]);
            }
            
        })
        .catch((error) => {
          //console.log(error);
          
        })
    } catch (e) {
      //console.log(e);
    
    }

}

function getOrdinalNumberJob() {

  ordDataRef.current = [];
  setItemOrdinalData([]);

  /* let pagecount= (ordinalDataTo-1) / (ordinalDataFrom-1); 
  let current = 1;
  for (let i = Number(ordinalDataFrom); i <= Number(ordinalDataTo); i = i + 100) {
    //console.log(i, "running");
    setItemOrdinalDataButton('Read Ords ' + current + '/' + pagecount);
    let f = await getOrdinalNumberJobDo(i);
    current = current + 1;
  }
 */


  getOrdinalNumberJobDo(ordinalDataFrom, 1);


}



 function getOrdinalNumberJobDo(num, override) {


  let pagecount= (Number(ordinalDataTo)-1) / (Number(ordinalDataFrom)-1); 
  setItemOrdinalDataButton('Read Ords ' + num + ' / ' +  ordinalDataTo);

  //flatlistAdminRef.scrollToOffset({ animated: true, offset: 0 });
  ////console.log(flatlistAdminRef);
  try{
    
    
    let formData = new FormData();
    formData.append('target', num);
    formData.append('savetodb', '1');
    formData.append('override', override);
    
       
    //var url = apiurl + 'saveItem?itemID=' + selectedItemForDataView.id + '&ordID=' + saveOrdId + "&address=" + saveAddress + "&transID=" + saveTransId + "&timestamp=" + savetimestamp + "&twitter=" + saveTwitterId + "&discord=" + saveDiscordId;
    if (searchOrdinalDataOnlyDigits) {
      var url = apiurl + 'getordinals';
    } else {
    }
    //console.log('get items to database...', url);
    
    fetch(url, {
        
        method: 'POST',
        headers: {
            'Accept': 'application/json'
        },
        body: formData
    })
        .then((response) => response.json())
        .then((json) => {
            //console.log(json.error);
            if (!json.error) {  

              let ordinalsold =  ordDataRef.current;
              let ordinalsnew = json.data;
              const ordinalstot = ordinalsold.concat(ordinalsnew);
              ordDataRef.current = ordinalstot;
                        
              //console.log("data Loaded")

              
              //onto the next one
              let next = Number(num) + 100;
              if (next<=Number(ordinalDataTo)) {

                getOrdinalNumberJobDo(next, 0);

              } else {
                ordDataRef.current.sort((a, b) => a.num - b.num);
                setItemOrdinalData(ordDataRef.current);
                setItemOrdinalDataButton('Read Ords');
                setAdminReadOrdsVisible(true);
              }
            } else {
              //console.log(json.message);
              //getOrdinalNumberJobDo(num);
              alert(json.message + " ... exiting");
            }

            
        })
        .catch((error) => {
          getOrdinalNumberJobDo(num, 1);
        })
  } catch (e) {
    getOrdinalNumberJobDo(num, 1);
  }


  

}



useEffect(() => {
  window.addEventListener('keydown', downHandler);
  window.addEventListener('keyup', upHandler);
  return () => {
    window.removeEventListener('keydown', downHandler);
    window.removeEventListener('keyup', upHandler);
  };
}, []);


const [itemRank, setItemRank] = React.useState('');


const updateOrdinal = (field, id, value, extra) => {

    //console.log(field, id, value)


    setItemAdminData(
        itemAdminData.map((upditem, index) =>
        
                  (upditem.id === id && field==='number') ? { ...upditem, item_id: value, updated: 1}
                : (upditem.id === id && field==='rank') ? { ...upditem, item_rank: value, updated: 1}
                : (upditem.id === id && field==='ordinal_id') ? { ...upditem, item_ordinal: value, updated: 1}
                : (upditem.id === id && field==='timestamp') ? { ...upditem, item_timestamp: value, updated: 1}
                : (upditem.id === id && field==='timezone') ? { ...upditem, item_timestamp_timezone: value, updated: 1}
                : (upditem.id === id && field==='address') ? { ...upditem, item_address: value, updated: 1}
                : (upditem.id === id && field==='transaction') ? { ...upditem, item_transaction: value, updated: 1}
                : (upditem.id === id && field==='verified') ? { ...upditem, item_verified: value, updated: 1}
                : (upditem.id === id && field==='ordinal') ? { ...upditem, item_id: value, btc_ordinal: extra, updated: 1}
                : (upditem.id === id && field==='checkordinal') ? { ...upditem, checkordinal: value}
                : { ...upditem }
        )
    );

};


const stylesDropApprove = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#efefef',
    height: 50,
    zIndex: 1,
  },
  buttonText: {
    flex: 1,
    textAlign: 'center',
  },
  icon: {
    marginRight: 10,
  },
  dropdownApprove: {
    position: 'absolute',
    left: clickLocationX,
    top: clickLocationY,
    backgroundColor: '#FFF',
    width: 80,
    height: 60,
    shadowColor: '#000000',
    shadowRadius: 4,
    shadowOffset: { height: 4, width: 0 },
    shadowOpacity: 0.5,
  },
  dropdownOrdinal: {
    position: 'absolute',
    left: clickLocationX,
    top: clickLocationY,
    backgroundColor: '#FFF',
    width: 80,
    height: ScreenHeight/2,
    shadowColor: '#000000',
    shadowRadius: 4,
    shadowOffset: { height: 4, width: 0 },
    shadowOpacity: 0.5,
  },
  confirmPanel: {
    position: 'absolute',
    left: ScreenWidth/2-100,
    top: ScreenHeight/2-50,
    backgroundColor: '#FFF',
    width: 400,
    height: 200,
    shadowColor: '#000000',
    shadowRadius: 4,
    shadowOffset: { height: 4, width: 0 },
    shadowOpacity: 0.5,
  },
  readOrdPanel: {
    position: 'absolute',
    left: ScreenWidth/2-700,
    top: ScreenHeight/2-300,
    backgroundColor: '#FFF',
    width: 1400,
    height: 600,
    shadowColor: '#000000',
    shadowRadius: 4,
    shadowOffset: { height: 4, width: 0 },
    shadowOpacity: 0.5,
  },
  overlay: {
    width: '100%',
    height: '100%',
  },
  item: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderBottomWidth: 1,
    cursor: 'pointer'
  },
});


const renderApproveDropdownItem = ({ item }) => (
  <TouchableWithoutFeedback style={stylesDropApprove.item} onPress={() => {
        updateOrdinal('verified', selectedItemForEdit, item.value);
        setApproveDropdownVisible(false);
      }}>
    <Text style={{cursor:'pointer', fontFamily:'ShareTechMono', height: 30, fontSize:'1.2rem'}}>{item.label}</Text>
  </TouchableWithoutFeedback>
);

const renderApproveDropdown = (id) => {

  let approveOptions = [{"ord_id": id, "value": '0', "label":'Raw'},{"ord_id": id, "value": '1', "label":'Approve'}];

  return (
    <Modal visible={approveDropdownVisible} transparent={true} animationType="none">
      <TouchableWithoutFeedback
        style={stylesDropApprove.overlay}
        onPress={() => setApproveDropdownVisible(false)}
      >
        <View style={[stylesDropApprove.dropdownApprove, { }]}>
          <FlatList
            data={approveOptions}
            renderItem={renderApproveDropdownItem}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};


const renderOrdinalDropdownItem = ({ item }) => (
  <View key={item.id} style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#FFFFFF", width: '100%'}}>
    <TouchableWithoutFeedback style={stylesDropApprove.item} onPress={() => {
          updateOrdinal('ordinal', selectedItemForEdit, item.id, item.btc_ordinal);
          setOrdinalDropdownVisible(false);
        }}>
      <Text style={{cursor:'pointer', fontFamily:'ShareTechMono', height: 30, fontSize:'1.2rem'}}>{item.btc_ordinal}</Text>
    </TouchableWithoutFeedback>
  </View>
);

const renderOrdinalDropdown = (id) => {

  return (
    <Modal visible={ordinalDropdownVisible} transparent={true} animationType="none">
      
      <View style={[stylesDropApprove.dropdownOrdinal, { }]}>

          <View style={{padding: 2, justifyContent:'center', backgroundColor: "#0000", width: '100%'}}>
                <TouchableWithoutFeedback
                      style={stylesDropApprove.overlay}
                      onPress={() => setOrdinalDropdownVisible(false)}
                    >
                      <View style={[stylesItem.smallroundbutton,{borderWidth:3, cursor:'pointer'}]}>
                          <Text style={[stylesItem.middleTextStyle,{}]}>Close</Text>
                      </View>
                </TouchableWithoutFeedback>
          </View>

          <View style={{padding: 2, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '100%'}}>
            <TextInput
               style={{fontFamily:'ShareTechMono', textAlign:'center', paddingLeft:0, height: 30, fontSize:'1rem',  backgroundColor: '#0000', width: "99%",fontWeight:'bold', color: 'black', borderWidth: 1,  marginBottom: 0 }}
                        onChangeText={newText => setSearchOrdinalSelect(newText)}
                        value={searchOrdinalSelect}
                        placeholder={'search'}/>
          </View>  

          <TouchableWithoutFeedback
            style={stylesDropApprove.overlay}
            onPress={() => setOrdinalDropdownVisible(false)}
          >
            <View style={[ {height: stylesDropApprove.dropdownOrdinal.height}]}>
              <FlatList
                data={ordinalSelectOptions.filter((o) => o.btc_ordinal.toLowerCase().includes(searchOrdinalSelect.toLowerCase()))}
                renderItem={renderOrdinalDropdownItem}
                keyExtractor={(item, index) => index.toString()}
              />
            </View>
          </TouchableWithoutFeedback>
      </View>
    </Modal>
  );
};

const renderAdminPass = () => {
  
  return (
    <Modal visible={openAdminPass} transparent={true} animationType="none">
        <View style={[stylesDropApprove.confirmPanel, { flexDirection:'column'}]}>
        
              <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#FFFFFF", width: '100%', flex:1}}>
                <Text style={[stylesItem.middleTextStyle,{}]}>Please provide password</Text>
              </View>
              <View style={{height:50, paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '100%'}}>
                  <TextInput
                        style={{fontFamily:'ShareTechMono', height: 30, fontSize:'1.2rem', textAlign:'center',  backgroundColor: '#0000', width: "99%",fontWeight:'bold', color: 'black', borderWidth: 3,  marginBottom: 0 }}
                        onChangeText={newText => setAdminPass(newText)}
                        editable = {true}
                        placeholder={'password'}/> 
              </View>
              <View style={{height:50, paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '100%', flexDirection:'row'}}>
                  <View style={{paddingLeft: 10, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '25%'}}>
                    <TouchableWithoutFeedback
                      style={stylesDropApprove.overlay}
                      onPress={() => sendAdminVerification()}
                    >
                      <View style={{borderWidth:2, cursor:'pointer'}}>
                          <Text style={[stylesItem.middleTextStyle,{}]}>GO</Text>
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
                  <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '50%'}}>
                    
                      <View style={{borderWidth:0, cursor:'pointer'}}>
                        <CheckBox
                            value={adminPassIamHuman}
                            onValueChange={setAdminPassIamHuman}
                            style={{alignSelf: 'center'}}
                          />
                          <Text style={[stylesItem.middleTextStyle,{}]}>I am not a robot</Text>
                      </View>
                    
                  </View>
                  <View style={{paddingRight: 10, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '25%'}}>
                    <TouchableWithoutFeedback
                      style={stylesDropApprove.overlay}
                      onPress={() => setOpenAdminPass(false)}
                    >
                      <View style={{borderWidth:2, cursor:'pointer'}}>
                          <Text style={[stylesItem.middleTextStyle,{}]}>CANCEL</Text>
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
              </View>
        </View>  
    </Modal>
  );
};


const renderAdminConfirmDelete = () => {
  
  return (
    <Modal visible={confirmAdminDeleteVisible} transparent={true} animationType="none">
        <View style={[stylesDropApprove.confirmPanel, { flexDirection:'column'}]}>
            
              <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#FFFFFF", width: '100%', flex:1}}>
                <Text style={[stylesItem.middleTextStyle,{}]}>Wish to proceed with the save?</Text>
              </View>
              <View style={{height:50, paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '100%', flexDirection:'row'}}>
                  <View style={{paddingLeft: 10, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '25%'}}>
                    <TouchableWithoutFeedback
                      style={stylesDropApprove.overlay}
                      onPress={() => deleteAdminData()}
                    >
                      <View style={{borderWidth:2, cursor:'pointer'}}>
                          <Text style={[stylesItem.middleTextStyle,{}]}>DELETE</Text>
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
                  <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '50%'}}>
                    
                      <View style={{borderWidth:0, cursor:'pointer'}}>
                        <CheckBox
                            value={adminDeleteIamHuman}
                            onValueChange={setAdminDeleteIamHuman}
                            style={{alignSelf: 'center'}}
                          />
                          <Text style={[stylesItem.middleTextStyle,{}]}>I am not a robot</Text>
                      </View>
                    
                  </View>
                  <View style={{paddingRight: 10, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '25%'}}>
                    <TouchableWithoutFeedback
                      style={stylesDropApprove.overlay}
                      onPress={() => {
                          setConfirmAdminDeleteVisible(false)
                          setAdminDeleteIamHuman(false);
                      }}>
                      <View style={{borderWidth:2, cursor:'pointer'}}>
                          <Text style={[stylesItem.middleTextStyle,{}]}>CANCEL</Text>
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
              </View>
        </View>  
    </Modal>
  );
};


const renderAdminConfirm = () => {
  
  return (
    <Modal visible={confirmAdminSaveVisible} transparent={true} animationType="none">
        <View style={[stylesDropApprove.confirmPanel, { flexDirection:'column'}]}>
            
              <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#FFFFFF", width: '100%', flex:1}}>
                <Text style={[stylesItem.middleTextStyle,{}]}>Wish to proceed with the save?</Text>
              </View>
              <View style={{height:50, paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '100%', flexDirection:'row'}}>
                  <View style={{paddingLeft: 10, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '25%'}}>
                    <TouchableWithoutFeedback
                      style={stylesDropApprove.overlay}
                      onPress={() => saveAdminData()}
                    >
                      <View style={{borderWidth:2, cursor:'pointer'}}>
                          <Text style={[stylesItem.middleTextStyle,{}]}>SAVE</Text>
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
                  <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '50%'}}>
                    
                      <View style={{borderWidth:0, cursor:'pointer'}}>
                        <CheckBox
                            value={adminSaveIamHuman}
                            onValueChange={setAdminSaveIamHuman}
                            style={{alignSelf: 'center'}}
                          />
                          <Text style={[stylesItem.middleTextStyle,{}]}>I am not a robot</Text>
                      </View>
                    
                  </View>
                  <View style={{paddingRight: 10, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '25%'}}>
                    <TouchableWithoutFeedback
                      style={stylesDropApprove.overlay}
                      onPress={() => {
                         setConfirmAdminSaveVisible(false)
                         setAdminSaveIamHuman(false);
                      }}>
                      <View style={{borderWidth:2, cursor:'pointer'}}>
                          <Text style={[stylesItem.middleTextStyle,{}]}>CANCEL</Text>
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
              </View>
        </View>  
    </Modal>
  );
};



const getSelectedItemForCheck=  (item) =>  {

  fetch("https://ordinals.com/preview/" + item.item_ordinal)
  .then(response => response.text())
  .then(data => {
    //console.log(data)
    var ordinalText = data.split('<pre>')[1].split('</pre>')[0];

      updateOrdinal('checkordinal', item.id, ordinalText);
      

  });
 


}

const renderOrdinalAdminItem = ({ item, index }) => (
 
     <View key={item.id} style={{backgroundColor: ( item.item_verified == 0 ? (index % 2 == 0 ? '#f8f8ff' : '#fffaf0') : (index % 2 == 0 ? '#20b2aa' : '#66cdaa')), borderWidth:1, height:135, width: '100%', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
          <View style={{height:'100%', width:'90%', flexDirection:'column', alignItems:'center', justifyContent:'flex-start'}}>
            <View style={{height:32, flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
                <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '8%'}}>

                    <TouchableWithoutFeedback
                            style={[stylesItem.button]}
                            onPress={(evt) => {
                                  //console.log(evt);
                                  setClickLocationX(evt.clientX);
                                  setClickLocationY(evt.clientY);
                                  setSelectedItemForEdit(item.id);  
                                  setOrdinalSelectType(item.collection_id);
                                  setOrdinalDropdownVisible(true);  
                                  
                                
                              }}>
                            <View style={[stylesItem.button,{backgroundColor: "#252525", color:'white', cursor:'pointer', width:'99%', height: 30, alignItems:'center', justifyContent:'center', borderWidth:3, borderColor: "#252525"}]}>
                                <Text style={[stylesItem.middleTextStyle,{color:'white', backgroundColor: "#0000"}]}>{item.btc_ordinal}</Text>
                            </View>
                      </TouchableWithoutFeedback>

                      {/* ez valojaban nem kell de maskepp nem szepen renderel */}
                     <TextInput
                        style={{fontFamily:'ShareTechMono', height: 0, fontSize:'1.2rem', textAlign:'center',  backgroundColor: (item.item_verified>0 ? '#0000' : '#0000'), width: "99%",fontWeight:'bold', color: 'black', borderWidth: 0,  marginBottom: 0 }}
                        value={item.btc_ordinal}
                        editable = {false}
                        placeholder={item.btc_ordinal}/>  
                </View>    
                <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '15%'}}>
                    <TextInput
                        style={{fontFamily:'ShareTechMono', height: 30, fontSize:'1.2rem', textAlign:'center', backgroundColor: (item.item_verified>0 ? '#0000' : '#0000'), width: "99%",fontWeight:'bold', color: 'black', borderWidth: 3,  marginBottom: 0 }}
                        onChangeText={newText => updateOrdinal('rank', item.id, newText)}
                        defaultValue={item.item_rank}
                        placeholder={item.item_rank}/>
                </View>
                <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '30%'}}>
                    <TextInput
                        style={{fontFamily:'ShareTechMono', textAlign:'center', paddingLeft:3, height: 30, fontSize:'1.2rem',  backgroundColor: (item.item_verified>0 ? '#0000' : '#0000'), width: "99%",fontWeight:'bold', color: 'black', borderWidth: 3,  marginBottom: 0 }}
                        onChangeText={newText => updateOrdinal('timestamp', item.id, newText)}
                        defaultValue={item.item_timestamp}
                        placeholder={item.item_timestamp}/>
                </View>    
                <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '14%'}}>
                    <TextInput
                        style={{fontFamily:'ShareTechMono', textAlign:'center', paddingLeft:3, height: 30, fontSize:'1.2rem',  backgroundColor: (item.item_verified>0 ? '#0000' : '#0000'), width: "99%",fontWeight:'bold', color: 'black', borderWidth: 3,  marginBottom: 0 }}
                        onChangeText={newText => updateOrdinal('timezone', item.id, newText)}
                        defaultValue={item.item_timestamp_timezone}
                        placeholder={item.item_timestamp_timezone}/>
                </View>    
                <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '13%'}}>
                    {/* <TextInput
                        style={{fontFamily:'ShareTechMono', textAlign:'center', paddingLeft:3, height: 30, fontSize:'1.2rem',  backgroundColor: (item.item_verified>0 ? '#0000' : '#0000'), width: "99%",fontWeight:'bold', color: 'black', borderWidth: 3,  marginBottom: 0 }}
                        onChangeText={newText => updateOrdinal('verified', item.id, newText)}
                        defaultValue={item.item_verified}
                        placeholder={item.item_verified}/> */}
                    
                    
                    <TouchableWithoutFeedback
                            style={[stylesItem.button]}
                            onPress={(evt) => {
                                  //console.log(evt);
                                  setClickLocationX(evt.clientX);
                                  setClickLocationY(evt.clientY);
                                  setSelectedItemForEdit(item.id);           
                                  setApproveDropdownVisible(true);  
                                
                              }}>
                            <View style={[stylesItem.button,{backgroundColor: "#0000", color:'black', cursor:'pointer', width:'99%', height: 30, alignItems:'center', justifyContent:'center', borderWidth:2, borderColor: "#252525"}]}>
                                <Text style={[stylesItem.middleTextStyle,{backgroundColor: "#0000"}]}>{item.item_verified==1? 'Approved' : 'Raw'}</Text>
                            </View>
                      </TouchableWithoutFeedback>


                </View>    

              
                <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: (item.item_verified>0 ? '#0000' : '#0000'), width: '20%'}}>
                    <View style={{cursor:'pointer', width: '99%', height: 30, alignItems:'center', justifyContent:'center', backgroundColor: (item.item_verified>0 ? '#0000' : '#0000'), borderWidth: 3, borderRadius:5}}>
                      <Text style={{fontFamily:'ShareTechMono', color: 'grey', cursor:'pointer',  fontSize: '1.2rem'}}
                              onPress={() => Linking.openURL('https://ordinals.com/inscription/' + item.item_ordinal)}> Ordinals⇨ 
                      </Text>
                    </View>
                </View>
            </View >
            <View style={{paddingBottom: 0, width: '100%', height:32, flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
                <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '8%'}}>
                    <TextInput
                        style={{fontFamily:'ShareTechMono', height: 30, fontSize:'1.2rem', textAlign:'center',  backgroundColor: '#0000', width: "99%",fontWeight:'bold', color: 'black', borderWidth: 0,  marginBottom: 0 }}
                        value={'Ord'}
                        editable = {false}
                        // placeholder={'Ord'}
                        />
                </View>    
                <View style={{paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '92%'}}>
                    <TextInput
                        style={{fontFamily:'ShareTechMono', height: 30, fontSize:'1.2rem', textAlign:'center', backgroundColor: (item.item_verified>0 ? '#0000' : '#0000'), width: "100%",fontWeight:'bold', color: 'black', borderWidth: 3,  marginBottom: 0 }}
                        onChangeText={newText => updateOrdinal('ordinal_id', item.id, newText)}
                        value={item.item_ordinal}
                        //editable = {false}
                        //placeholder={item.item_ordinal}
                        />
                </View>
            </View >
            <View style={{paddingBottom: 0, width: '100%', height:32, flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
                <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '8%'}}>
                    <TextInput
                        style={{fontFamily:'ShareTechMono', height: 30, fontSize:'1.2rem', textAlign:'center',  backgroundColor: '#0000', width: "99%",fontWeight:'bold', color: 'black', borderWidth: 0,  marginBottom: 0 }}
                        value={'Addr'}
                        editable = {false}
                        //placeholder={'Addr'}
                        />
                </View>    
                <View style={{paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '92%'}}>
                    <TextInput
                        style={{fontFamily:'ShareTechMono', height: 30, fontSize:'1.2rem', textAlign:'center', backgroundColor: (item.item_verified>0 ? '#0000' : '#0000'), width: "100%",fontWeight:'bold', color: 'black', borderWidth: 3,  marginBottom: 0 }}
                        onChangeText={newText => updateOrdinal('address', item.id, newText)}
                        value={item.item_address}
                        //placeholder={item.item_address}
                        />
                </View>
            </View >
            <View style={{paddingBottom: 0, width: '100%', height:32, flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
                <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '8%'}}>
                    <TextInput
                        style={{fontFamily:'ShareTechMono', height: 30, fontSize:'1.2rem', textAlign:'center',  backgroundColor: '#0000', width: "99%",fontWeight:'bold', color: 'black', borderWidth: 0,  marginBottom: 0 }}
                        value={'Tx'}
                        editable = {false}
                        //placeholder={'Tx'}
                        />
                </View>    
                <View style={{paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '92%'}}>
                    <TextInput
                        style={{fontFamily:'ShareTechMono', height: 30, fontSize:'1.2rem', textAlign:'center', backgroundColor: (item.item_verified>0 ? '#0000' : '#0000'), width: "100%",fontWeight:'bold', color: 'black', borderWidth: 3,  marginBottom: 0 }}
                        onChangeText={newText => updateOrdinal('transaction', item.id, newText)}
                        //defaultValue={item.item_transaction}
                        value={item.item_transaction}
                        //placeholder={item.item_transaction}
                        />
                </View>
            </View >
          </View>
          <View style={{paddingTop:0, height:'100%', width:'10%', flexDirection:'column', alignItems:'center', justifyContent:'flex-start'}}>
                    <View style={{padding: 5, justifyContent:'center', backgroundColor: "#0000", width: '100%'}}>
                      <TouchableWithoutFeedback
                            style={[stylesItem.button]}
                            onPress={() => checkAdminData()}>
                            <View style={[stylesItem.button,{color:'black', cursor:'pointer', width:90, height:25, alignItems:'center', justifyContent:'center', backgroundColor:'#FFF', borderWidth:2, borderColor: "#252525"}]}>
                                <Text style={[stylesItem.notSoBigTextStyle,{}]}>save</Text>
                            </View>
                      </TouchableWithoutFeedback>
                    </View>
                    <View style={{paddingLeft:5, justifyContent:'center', backgroundColor: "#0000", width: '100%'}}>
                      <TouchableWithoutFeedback
                            style={[stylesItem.button]}
                            onPress={() => {
                              setSelectedItemForDelete(item)
                              setConfirmAdminDeleteVisible(true);
                            }}>
                            <View style={[stylesItem.button,{color:'black', cursor:'pointer', width:90, height:25, alignItems:'center', justifyContent:'center', backgroundColor:'#FFF', borderWidth:2, borderColor: "#252525"}]}>
                                <Text style={[stylesItem.notSoBigTextStyle,{}]}>delete</Text>
                            </View>
                      </TouchableWithoutFeedback>
                      </View>
                    <View style={{paddingLeft:5,paddingTop:5, justifyContent:'center', backgroundColor: "#0000", width: '100%'}}>
                      <TouchableWithoutFeedback
                            style={[stylesItem.button]}
                            onPress={() => {
                              getSelectedItemForCheck(item);
                            }}>
                            <View style={[stylesItem.button,{color:'black', cursor:'pointer', width:90, height:25, alignItems:'center', justifyContent:'center', backgroundColor:'#FFF', borderWidth:2, borderColor: "#252525"}]}>
                                <Text style={[stylesItem.notSoBigTextStyle,{}]}>check</Text>
                            </View>
                      </TouchableWithoutFeedback>
                    </View>
                    <View style={{paddingLeft:5,paddingTop:5, justifyContent:'center', backgroundColor: "#0000", width: '100%'}}>
                      
                            <View style={[stylesItem.button,{color: 'white', width:90, height:25, alignItems:'center', justifyContent:'center', backgroundColor: (item.checkordinal=='' ? '#0000' : '#252525'), borderWidth:0, borderColor: "#252525"}]}>
                                <Text style={[stylesItem.notSoBigTextStyle,{color:'white'}]}>{item.checkordinal}</Text>
                            </View>
                      
                    </View>
          </View>
          
      </View>
    
)


const renderAdminReadOrds = () => {
  
  return (
    <Modal visible={adminReadOrdsVisible} transparent={true} animationType="none">
        <View style={[stylesDropApprove.readOrdPanel, { flexDirection:'column'}]}>

            <View style={{height:80, paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#FFFFFF", width: '100%'}}>

                          <TextInput
                               value={searchOrdinalData}
                               style={{fontFamily:'ShareTechMono', backgroundColor: "#fff", padding: 5, width:'98%', borderWidth:2,  }}
                               color="#1B152D"
                               keyboardType='email-address'
                               autoCapitalize='none'
                               placeholder='search'
                               //accessoryRight={renderIcon}
                               onChangeText={nextValue => setSearchOrdinalData(nextValue)}></TextInput>
                               
              </View>
              <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#FFFFFF", width: '100%', flex:1}}>
              
                    <FlatList
                        style={{ backgroundColor: "#f1f1f1", padding:5, flex:1 }}
                        data={itemOrdinalData.filter((p) => p.searchstr.toLowerCase().includes(searchOrdinalData.toLowerCase()))}
                        renderItem={renderAdminReadOrdsItem}
                        keyExtractor={(item,index)=> index.toString()}
                        />

              </View>
              <View style={{height:50, paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '100%', flexDirection:'row'}}>
                  <View style={{paddingLeft: 10, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '25%'}}>
                    <TouchableWithoutFeedback
                      style={stylesDropApprove.overlay}
                      onPress={() => setAdminReadOrdsVisible(false)}
                    >
                      <View style={{borderWidth:2, cursor:'pointer'}}>
                          <Text style={[stylesItem.middleTextStyle,{}]}>OK</Text>
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
                  
                  <View style={{paddingRight: 10, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '25%'}}>
                    <TouchableWithoutFeedback
                      style={stylesDropApprove.overlay}
                      onPress={() => {
                        setAdminReadOrdsVisible(false)
                         
                      }}>
                      <View style={{borderWidth:2, cursor:'pointer'}}>
                          <Text style={[stylesItem.middleTextStyle,{}]}>CANCEL</Text>
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
              </View>
        </View>  
    </Modal>
  );
};

const renderAdminReadOrdsItem = ({ item, index }) => (
 
  <View key={index} style={{backgroundColor: '#0000', borderWidth:1, height:30, width: '100%', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
       
            <Text style={[stylesItem.textStyle, {paddingBottom:0, textAlign:'left', textAlignVertical:'center', color:(item.haserror==false ? 'black' : 'red')}]}>{item.haserror + ";" + item.num + ';' + item.ordinal_rank + ';' + item.address + ';' + item.trans + ';' + item.sat + ';' + item.tstamp}</Text>         
    
  </View>
 
)


function renderAdmin () {

  return (

    <Modal visible={isAdminVisible}
              animationType="slide"
              transparent={true}>
              <View style={stylesItem.centeredView}>
                  
              {renderApproveDropdown()}
              {renderOrdinalDropdown()}
              {renderAdminConfirm()}
              {renderAdminConfirmDelete()}
              {renderAdminReadOrds()}
              
              

                      {/* edit */}
                      <View style={[stylesItem.modalViewBig, {flexDirection:'column'}]}>
                        
                        {/* <View style={{width: 1200, height: 600, backgroundColor: "#000000", width: '100%', flexDirection: "row"}}> */}

                            
                            <View style={{paddingLeft: 2, height: '100%', backgroundColor: "#0000", width: '100%'}}> {/* gomb miatt kell lent */}
                                {/* DataView */}
                                <View style={{height:'90%', backgroundColor: "#FFFFFF", width: '100%', flexDirection:"column" }}>
                                    <View style={{paddingLeft: 20, paddingTop: 0, backgroundColor: "#FFFFFF", width: '100%'}}>
                                      <Text style={[stylesItem.bigTextStyle, {paddingBottom:5, textAlign:'left', textAlignVertical:'center'}]}>Admin</Text>
                                      <TextInput
                                          value={searchAdmin}
                                          style={{fontFamily:'ShareTechMono', backgroundColor: "#fff", padding: 5, width:'98%', borderWidth:2,  }}
                                          color="#1B152D"
                                          keyboardType='email-address'
                                          autoCapitalize='none'
                                          placeholder='search'
                                          //accessoryRight={renderIcon}
                                          onChangeText={nextValue => setSearchAdmin(nextValue)}
                                      /> 
                                      {/* <View
                                        style={{
                                          paddingTop:5,
                                          width: 50,
                                          borderBottomColor: 'black',
                                          borderBottomWidth: 2,
                                        }} 
                                      />*/}
                                    </View>
                                    <View style={{paddingLeft: 100, paddingTop: 5, backgroundColor: "#0000", width: '86%', flexDirection:'row'}}>
                                      <Text style={[stylesItem.middleTextStyle, {width: '16%',backgroundColor:'#0000', paddingBottom:0, textAlign:'left', textAlignVertical:'center'}]}>Rank</Text>
                                      <Text style={[stylesItem.middleTextStyle, {width: '33%',backgroundColor:'#0000', paddingBottom:0, textAlign:'left', textAlignVertical:'center'}]}>Timestamp (yyyy-mm-dd hh:mm:ss)</Text>
                                      <Text style={[stylesItem.middleTextStyle, {width: '16%',backgroundColor:'#0000', paddingBottom:0, textAlign:'left', textAlignVertical:'center'}]}>Timezone</Text>
                                      <Text style={[stylesItem.middleTextStyle, {width: '14%',backgroundColor:'#0000', paddingBottom:0, textAlign:'left', textAlignVertical:'center'}]}>Status</Text>
                                      <Text style={[stylesItem.middleTextStyle, {width: '13%',backgroundColor:'#0000', paddingBottom:0, textAlign:'left', textAlignVertical:'center'}]}>Link</Text>
                                    </View>
                                    <View style={{height: '85%', paddingLeft: 20, paddingTop: 0, backgroundColor: "#FFFFFF", width: '98%'}}>

                                              <FlatList
                                                style={{ backgroundColor: "#f1f1f1", padding:5, flex:1 }}
                                                data={itemAdminData.filter((p) => p.searchfield.toLowerCase().includes(searchAdmin.toLowerCase()))}
                                                renderItem={renderOrdinalAdminItem}
                                                keyExtractor={(item,index)=> item.id.toString()}
                                                ref={flatlistAdminRef}
                                              />
                                              

                                    </View>
                                </View>
                                
                                <View style={{paddingTop:10, height:'15%', backgroundColor: "#FFFFFF", width: '100%', flexDirection:"row"}}>
                                    <View style={{paddingLeft: 20, height:'100%', backgroundColor: "#FFFFFF", width: '10%', alignItems:'flex-start' }}>
                                      <TouchableWithoutFeedback
                                        style={[stylesItem.button]}
                                        onPress={() => setAdminVisible(false)}>
                                        <View style={[stylesItem.button,{color:'black', cursor:'pointer', width:60, height:60, alignItems:'center', justifyContent:'flex-end', backgroundColor:'#FFF', borderWidth:2, borderColor: "#252525"}]}>
                                          <Text style={[stylesItem.bigTextStyle,{}]}>ok</Text>
                                        </View>
                                      </TouchableWithoutFeedback>
                                    </View>
                                    <View style={{paddingRight: 30, height:'100%', backgroundColor: "#FFFFFF", width: '11%', alignItems:'flex-end', flexDirection:'column' }}>
                                        <TouchableWithoutFeedback
                                            style={[stylesItem.button]}
                                            onPress={() => getNewOrdinalNumbers()}>
                                            <View style={[stylesItem.button,{color:'black', cursor:'pointer', width:100, height:32, alignItems:'center', justifyContent:'flex-end', backgroundColor:'#FFF', borderWidth:2, borderColor: "#252525"}]}>
                                              <Text style={[stylesItem.middleTextStyle,{}]}>New</Text>
                                            </View>
                                          </TouchableWithoutFeedback>
                                          <CheckBox
                                            value={searchOrdinalDataOnlyDigits}
                                            onValueChange={setSearchOrdinalDataOnlyDigits}
                                            style={{height:22, paddingTop: 5, alignSelf: 'center'}}
                                          />
                                          <Text style={[stylesItem.middleTextStyle,{width:100}]}>Only digits</Text>
                                    </View>
                                    <View style={{paddingRight: 30, height:'100%', backgroundColor: "#FFFFFF", width: '9%', alignItems:'flex-end' , flexDirection:'column' }}>
                                      
                                      
                                      <TextInput
                                            style={{padingRight:0, fontFamily:'ShareTechMono', height: 30, fontSize:'1.0rem', backgroundColor: 'white', width: 100,fontWeight:'bold', color: 'black', borderWidth: 1,  marginBottom: 0 }}
                                            value={ordinalDataFrom}
                                            onChangeText={setOrdinalDataFrom}
                                            defaultValue={ordinalDataFrom}
                                            placeholder={ordinalDataFrom}/>
                                      
                                      
                                      <TextInput
                                            style={{padingRight:0, fontFamily:'ShareTechMono', height: 30, fontSize:'1.0rem', backgroundColor: 'white', width: 100,fontWeight:'bold', color: 'black', borderWidth: 1,  marginBottom: 0 }}
                                            value={ordinalDataTo}
                                            onChangeText={setOrdinalDataTo}
                                            defaultValue={ordinalDataTo}
                                            placeholder={ordinalDataTo}/>

                                    </View>
                                    <View style={{paddingRight: 5, height:'100%', backgroundColor: "#FFFFFF", width: '15%', alignItems:'flex-end' }}>
                                      <TouchableWithoutFeedback
                                        style={[stylesItem.button]}
                                      /*   onPress={() =>  startGetNumInscribed()} */>
                                        <View style={[stylesItem.button,{color:'black', cursor:'pointer', width:220, height:60, alignItems:'center', justifyContent:'flex-end', backgroundColor:'#FFF', borderWidth:2, borderColor: "#252525"}]}>
                                          <Text style={[stylesItem.middleTextStyle,{}]}>{itemOrdinalDataButton}</Text>
                                        </View>
                                      </TouchableWithoutFeedback>
                                    </View>
                                    <View style={{paddingRight: 5, height:'100%', backgroundColor: "#FFFFFF", width: 50, alignItems:'flex-end' }}>
                                      <TouchableWithoutFeedback
                                        style={[stylesItem.button]}
                                        onPress={() => alert("notused")}>
                                        <View style={[stylesItem.button,{color:'black', cursor:'pointer', width:50, height:60, alignItems:'center', justifyContent:'flex-end', backgroundColor:'#FFF', borderWidth:2, borderColor: "#252525"}]}>
                                          <Text style={[stylesItem.middleTextStyle,{}]}>OWNERS</Text>
                                        </View>
                                      </TouchableWithoutFeedback>
                                    </View>
                                    <View style={{paddingRight: 30, height:'100%', backgroundColor: "#FFFFFF", width: '20%', alignItems:'flex-end' }}>
                                      <TouchableWithoutFeedback
                                        style={[stylesItem.button]}
                                        onPress={() => addAdminData()}>
                                        <View style={[stylesItem.button,{color:'black', cursor:'pointer', width:170, height:60, alignItems:'center', justifyContent:'flex-end', backgroundColor:'#FFF', borderWidth:2, borderColor: "#252525"}]}>
                                          <Text style={[stylesItem.bigTextStyle,{}]}>add new</Text>
                                        </View>
                                      </TouchableWithoutFeedback>
                                    </View>
                                    <View style={{paddingRight: 20, height:'100%', backgroundColor: "#FFFFFF", width: '15%', alignItems:'flex-end' }}>
                                      <TouchableWithoutFeedback
                                        style={[stylesItem.button]}
                                        onPress={() => checkAdminData()}>
                                        <View style={[stylesItem.button,{color:'black', cursor:'pointer', width:170, height:60, alignItems:'center', justifyContent:'flex-end', backgroundColor:'#FFF', borderWidth:2, borderColor: "#252525"}]}>
                                          <Text style={[stylesItem.bigTextStyle,{}]}>save all</Text>
                                        </View>
                                      </TouchableWithoutFeedback>
                                    </View>
                                    <View style={{paddingRight: 20, height:'100%', backgroundColor: "#FFFFFF", width: '15%', alignItems:'flex-end' }}>
                                      <TouchableWithoutFeedback
                                        style={[stylesItem.button]}
                                        onPress={() => setForceReloadAdmin(!forceReloadAdmin)}>
                                        <View style={[stylesItem.button,{color:'black', cursor:'pointer', width:160, height:60, alignItems:'center', justifyContent:'flex-end', backgroundColor:'#FFF', borderWidth:2, borderColor: "#252525"}]}>
                                          <Text style={[stylesItem.bigTextStyle,{}]}>refresh</Text>
                                        </View>
                                      </TouchableWithoutFeedback>
                                    </View>
                                </View>
                            </View>
                          
                          

                        {/* </View> */}

                      

                      </View>
                  </View>
              
            </Modal> 
    

  )

}




  /* --------------------------------------------------------------------------- */

  const renderItemStyle = (item) => {
      if (isDesktopLandscape) {
            return (item.CIV > 0 ? styles.itemverified : (item.CIR > 0 ?  styles.itemregistered : styles.emptyitem));
      } else {
            return (item.CIV > 0 ? styles.itemverified_small : (item.CIR > 0 ?  styles.itemregistered_small : styles.emptyitem_small));
      }
  }
  
  const renderItem = ({ item, index }) => {

    return <View onMouseEnter={MouseEnter} onMouseLeave={onMouseLeave} style={renderItemStyle(item)} key={index} >{
                <TouchableWithoutFeedback onPress={() => {
                                    
                  setSelectedItemForDataView(item);
              
              }}>
                  <View style={{width:'100%', height:'100%',justifyContent: 'center', alignItems: 'center'}}>
                      <Text style={{ fontFamily:'ShareTechMono', fontSize: 22, color: (item.CIV > 0 ? '#252525' : 'white') }}>{item.btc_ordinal}</Text>
                      {/* <View style={{paddingTop: 5, width:'100%', height:24, flexDirection:'row',justifyContent: 'center', alignItems: 'center'}}>
                         <TouchableWithoutFeedback onPress={() => {
                          
                              setSelectedItemForRegistration(item);
                          
                          }}>
                          <View style={{width:'50%', height:26,justifyContent: 'center', alignItems: 'flex-start'}}>
                              <Image source={(item.CIV > 0 ? require('./img/register_black.png') : require('./img/register.png'))} style={{height:24, width:24}} ></Image>
                          </View>
                          </TouchableWithoutFeedback>
                          <TouchableWithoutFeedback onPress={() => {
                          
                              setSelectedItemForDataView(item);
                          
                          }}>
                          <View style={{width:'50%', height:26,justifyContent: 'center', alignItems: 'flex-end'}}>
                              <Image source={(item.CIV > 0 ? require('./img/view_black.png') : require('./img/view.png'))} style={{height:22, width:26}} ></Image>
                          </View>
                          </TouchableWithoutFeedback>
                      </View> */}
                 </View>                
             </TouchableWithoutFeedback>
        }</View>;
  };
  
  useEffect(() => {
    (async () => {

        loadCollections();

    })();

  }, [collectionType, filterType, sortType, filteredItem, subType, collectionIsFibo, collectionIsPoker, collectionIsPrime, collectionIsPalindrome]); 
  
  
  function loadCollections() {
      
      try{

          let collection = collectionType;
          let isfibo = 0;
          let isprime = 0;
          let ispoker = 0;
          let ispalindrome = 0;
          if (collectionIsPrime==1) {
           //   collection=100;
              isprime=1;
          }
          if (collectionIsFibo==1) {
           // collection=100;
            isfibo=1;
          }
          if (collectionIsPalindrome==1) {
            // collection=100;
             ispalindrome=1;
           }
          if (collectionIsPoker==1) {
            // collection=100;
             ispoker=1;
           }

          setLoading(true); 
          let limit = (isDesktopLandscape) ? 500 : 100;
          var url = apiurl + 'getNumbers?collection=' + collection + "&filter=" + filterType + "&sort=" + sortType + "&selected=" + filteredItem + "&offset=" + offset + "&limit=" + limit + "&isfibo=" + isfibo + "&isprime=" + isprime + "&ispalindrome=" + ispalindrome + "&ispoker=" + ispoker + "&subtype=" + subType;
          //console.log('Retrieved from database...', url);
          fetch(url, {
              
              method: 'GET',
              headers: {
                  'Accept': 'application/json'
              }
          })
              .then((response) => response.json())
              .then((json) => {
                //console.log(json.sql);
                if (limit > Object.keys(json.data).length) {
                  setHasMoreItemToLoad(false);
                } else {
                  setHasMoreItemToLoad(true);
                }
                
                  //console.log(json.data);
                  //console.log(Object.keys(json.data).length);

                  setOffset(offset + 1);
                  //Increasing the offset for the next API call
                  
                  if (offset==1) {
                    setNumbers(json.data)
                  } else {
                    setNumbers([...numbers, ...json.data]);
                  }

                  setLoading(false);
                  //console.log(numbers);

              })
              .catch((error) => {
                //console.log(error);
              })
      } catch (e) {
        //console.log(e);
      }
      setLoading(false);
    }

    

    const toggleAboutModal = () => {
      setAboutVisible(!isAboutVisible);
    };

    const toggleFaqModal = () => {
      setFaqVisible(!isFaqVisible);
    };

    //selected item for registration
    useEffect(() => {
      (async () => {
  
        if (selectedItemForRegistration=='') {
            setRegistrationPanelVisible(false);
        } else {
            setRegistrationPanelVisible(true);
            setDataViewPanelVisible(false);  
        }
          
      })();
  
    }, [selectedItemForRegistration]); 


    
//selected item get
    useEffect(() => {
      (async () => {
  
          loadItemData();
  
      })();
  
    }, [selectedItemForDataView]); 

      
    
    
    function loadItemData() {
        
        try{
      
            if (selectedItemForDataView=='') {
              return;
            }
           
            var url = apiurl + 'getItemData?itemID=' + selectedItemForDataView.btc_ordinal ;
           // console.log('Retrieved item from database...', url);
            fetch(url, {
                
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then((response) => response.json())
                .then((json) => {
                    setItemData(json.data)
                    //console.log(json.data);
                    cancelRegistrationPanel(false);
                    setDataViewPanelVisible(true);
                })
                .catch((error) => {
                  //console.log(error);
                })
        } catch (e) {
          //console.log(e);
        }
  
      }
  
            

    function checkTransaction() {

      
  
        
        if (saveOrdId=='') {
          alert("Please supply Ordinal Id");
          return;
        } else if (saveOrdId.length!=66) {
          alert("Please supply valid Ordinal Id : 66 chars");
          return;
        } else if (saveOrdId.slice(-2)===':0') {
          alert("Please supply valid Ordinal Id : 66 chars and not ending with :0");
          return;
        }

        /* if (saveAddress=='') {
          alert("Please supply address");
          return;
        }

        if (saveTransId=='') {
          alert("Please supply Transaction Id");
          return;
        }

        if (savetimestamp=='') {
          alert("Please supply Transaction timestamp");
          return;
        } */

        //opcionalis
        /* if (saveTwitterId=='') {
          alert("Please supply Twitter Id");
          return;
        } */

        if (saveDiscordId=='') {
          alert("Please supply Discord Id");
          return;
        }

      

      setConfirmRegistrationSaveVisible(true);

    }



    function saveTransaction() {

      if (!registrationIamHuman) {
        alert("Please check Robot check");
        return;
      }

      try{
     

        let formData = new FormData();
        formData.append('itemID', selectedItemForRegistration.id);
        formData.append('ordID', saveOrdId);
        formData.append('address', saveAddress);
        formData.append('transID', saveTransId);
        formData.append('timestamp', savetimestamp);
        formData.append('twitter', saveTwitterId);
        formData.append('discord', saveDiscordId);
        //var url = apiurl + 'saveItem?itemID=' + selectedItemForDataView.id + '&ordID=' + saveOrdId + "&address=" + saveAddress + "&transID=" + saveTransId + "&timestamp=" + savetimestamp + "&twitter=" + saveTwitterId + "&discord=" + saveDiscordId;
        
        var url = apiurl + 'saveItem';
        //console.log('save item to database...', url);
        fetch(url, {
            
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            body: formData
        })
            .then((response) => response.json())
            .then((json) => {
                alert(json.message);
                if (json.error == false) {
                  updateRegCount(json.item_id, json.regCountVer, json.regCountReg);
                  cancelRegistrationPanel();
                }
            })
            .catch((error) => {
              //console.log(error);
            })
    } catch (e) {
      //console.log(e);
    }

  }

  function updateRegCount(item_id, numVer, numReg) {
    
    if (filterType === 'Empty') { //tehat torolni kell mert
        if (numVer>0 || numReg>0) {
          setNumbers(numbers.filter((n) => item_id !== n.id));
        }
    } else {
      setNumbers(
        numbers.map((number, index) =>
            number.id === item_id
                ? { ...number, CIV: numVer, CIR: numReg }
                : { ...number }

        )
      )
    }

  }

  function setSaveVariablestoNull() {
    setSaveOrdId('');
    setSaveAddress('');
    setSaveTransId('');
    setSaveTwitterId('');
    setSaveDiscordId('');
    setSavetimestamp('');
  }

    
    function renderAbout() {

      return (

        <Modal visible={isAboutVisible}
                  animationType="fade"
                  transparent={true}>
                  <View style={stylesFaq.centeredView}>
                    <View style={stylesFaq.modalView}>
                      <Text style={[stylesFaq.modalText,{fontWeight:"bold", fontSize: (isDesktopLandscape) ? 20 : 15 }]}>About Bitcoin Digits{"\n"}{"\n"}
                            The very first and earliest registered digits in Ordinals are forever inscribed on the Bitcoin blockchain.
                      </Text>
                      
                      <TouchableWithoutFeedback
                        style={[stylesFaq.button, stylesFaq.buttonClose]}
                        onPress={() => toggleAboutModal()}>
                        <View style={[stylesFaq.button, stylesFaq.buttonClose,{cursor:'pointer', width:150, height: (isDesktopLandscape) ? 60 : 30, alignItems:'center', justifyContent:'center', backgroundColor:'#252525', borderWidth:1.5, borderColor: "#fff"}]}>
                          <Text style={[stylesFaq.buttonTextStyle, {fontSize: (isDesktopLandscape) ? 20 : 15}]}>Close About</Text>
                        </View>
                      </TouchableWithoutFeedback>
                    </View>
                  </View>
                </Modal> 

      )

    }

    
    function renderFaq () {

      return (

        <Modal visible={isFaqVisible}
                  animationType="slide"
                  transparent={true}>
                  <View style={stylesFaq.centeredView}>
                    <View style={stylesFaq.modalView}>
                      <Text style={[stylesFaq.modalText,{fontWeight:"bold", fontSize:  (isDesktopLandscape) ? 20 : 12}]}>F.A.Q.{"\n"}{"\n"}
                          - What is Bitcoin Digits?{"\n"}{"\n"}
                            Bitcoin Digits are community-first digit collection project on BTC, backed by a strong and ownable branding for fellow degenerates.{"\n"}{"\n"}
                          - How to mint?{"\n"}{"\n"}
                            You can mint the digits by clicking the Text section on                             
                            <Text style={{color: 'grey', cursor:'pointer',  fontSize:  (isDesktopLandscape) ? 20 : 12}}
                                onPress={() => Linking.openURL('https://gamma.io/ordinals')}> Ordinals 
                            </Text> website or upload a .txt file, that contatins the number you want to mint onto 
                            <Text style={{color: 'grey', cursor:'pointer',  fontSize:  (isDesktopLandscape) ? 20 : 12}}
                            onPress={() => Linking.openURL('https://ordinalsbot.com/')}> Ordinalbot  
                            </Text> website{"\n"}{"\n"}
                            - How to buy and sell?{"\n"}{"\n"}
                            This collection is traded on every major Ordinals marketplace, <Text style={{color: 'grey', cursor:'pointer',  fontSize:  (isDesktopLandscape) ? 20 : 12}}
                                onPress={() => Linking.openURL('https://magiceden.io/ordinals/marketplace/bitcoin-digits')}> Magic Eden 
                            </Text> or <Text style={{color: 'grey', cursor:'pointer',  fontSize:  (isDesktopLandscape) ? 20 : 12}}
                                onPress={() => Linking.openURL('https://www.okx.com/web3/marketplace/nft/collection/btc/bitcoin-digits-1')}> OKX 
                            </Text> and others... {"\n"}{"\n"}
                            - Why should i invest in Bitcoin Digits?{"\n"}{"\n"}
                            Bitcoin Digits are started to mint as a sub 1k, almost sub 100k inscription collection. Which means we're fucking early. We're the pioneers of the ordinals. {"\n"}This is going to be history, invest and be a part of it.
                      </Text>
                      
                      <TouchableWithoutFeedback
                        style={[stylesFaq.button, stylesFaq.buttonClose]}
                        onPress={() => toggleFaqModal()}>
                        <View style={[stylesFaq.button, stylesFaq.buttonClose,{cursor:'pointer', width:150, height: (isDesktopLandscape) ? 60 : 30, alignItems:'center', justifyContent:'center', backgroundColor:'#252525', borderWidth:1.5, borderColor: "#fff"}]}>
                          <Text style={[stylesFaq.buttonTextStyle, {fontSize:  (isDesktopLandscape) ? 20 : 12}]}>Close FAQ</Text>
                        </View>
                      </TouchableWithoutFeedback>
                    </View>
                  </View>
                </Modal> 

      )

    }

    function downHandler({key}) {
      if (key === 'x') {
        setXHeld(true);
      }
      if (key === 'Control') {
        setControlHeld(true);
      }
      if (key === 'y') {
        setYHeld(true);
      }
    }
  
    function upHandler({key}) {
      if (key === 'x') {
        setXHeld(false);
      }
      if (key === 'Control') {
        setControlHeld(false);
      }
      if (key === 'y') {
        setYHeld(false);
      }
      
    }

   


 /* ------------------------------------------------------ */


 const renderRegistrationConfirm = () => {
  
  return (
    <Modal visible={confirmRegistrationSaveVisible} transparent={true} animationType="none">
        <View style={[stylesDropApprove.confirmPanel, { flexDirection:'column'}]}>
            
              <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#FFFFFF", width: '100%', flex:1}}>
                <Text style={[stylesItem.middleTextStyle,{}]}>Wish to proceed with the save?</Text>
              </View>
              <View style={{height:50, paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '100%', flexDirection:'row'}}>
                  <View style={{paddingLeft: 10, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '25%'}}>
                    <TouchableWithoutFeedback
                      style={stylesDropApprove.overlay}
                      onPress={() => saveTransaction()}
                    >
                      <View style={{borderWidth:2, cursor:'pointer'}}>
                          <Text style={[stylesItem.middleTextStyle,{}]}>SAVE</Text>
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
                  <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '50%'}}>
                    
                      <View style={{borderWidth:0, cursor:'pointer'}}>
                        <CheckBox
                            value={registrationIamHuman}
                            onValueChange={setRegistrationIamHuman}
                            style={{alignSelf: 'center'}}
                          />
                          <Text style={[stylesItem.middleTextStyle,{}]}>I am not a robot</Text>
                      </View>
                    
                  </View>
                  <View style={{paddingRight: 10, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '25%'}}>
                    <TouchableWithoutFeedback
                      style={stylesDropApprove.overlay}
                      onPress={() => {
                        setConfirmRegistrationSaveVisible(false)
                        setRegistrationIamHuman(false)
                      }}>                    
                      <View style={{borderWidth:2, cursor:'pointer'}}>
                          <Text style={[stylesItem.middleTextStyle,{}]}>CANCEL</Text>
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
              </View>
        </View>  
    </Modal>
  );
};




    function cancelRegistrationPanel() {
      setSelectedItemForRegistration('');
      setSaveVariablestoNull();
      setRegistrationPanelVisible(false);
      setConfirmRegistrationSaveVisible(false);
      setRegistrationIamHuman(false);
    }

    
    function renderRegistrationPanel () {

      return (

        <Modal visible={isRegistrationPanelVisible}
                  animationType="slide"
                  transparent={true}>
                  <View style={stylesItem.centeredView}>

                    {renderRegistrationConfirm()}

                    <View style={stylesItem.modalView}>
                      <View style={{width: 800, height: 500, backgroundColor: "#0000", width: '100%', flexDirection: "row"}}>

                        <View style={{height: 500, backgroundColor: "#0000", width: '8%' , alignItems: 'center'}}>
                          <TouchableWithoutFeedback
                              style={[stylesItem.button, stylesItem.buttonClose]}
                              onPress={() => cancelRegistrationPanel()}>
                              <View style={{cursor:'pointer', height: 50, backgroundColor: "#252525", width: '100%', borderWidth:3, borderColor:"white", alignItems:'center', justifyContent:'center'}}>
                                  <Text style={stylesItem.bigTextStyleWhite}>X</Text>
                              </View>
                          </TouchableWithoutFeedback>
                        </View>
                        
                        <View style={{paddingLeft: 2, height: 500, backgroundColor: "#0000", width: '14%' , alignItems: 'center', justifyContent:'flex-start'}}>
                            <View style={{height: 50, backgroundColor: "#FFFFFF", width: '100%', alignItems:'center', justifyContent:'center'}}>
                              <Text style={stylesItem.bigTextStyle}>{selectedItemForRegistration.btc_ordinal}</Text>
                            </View>
                        </View>
                        
                        <View style={{paddingLeft: 2, height: 500, backgroundColor: "#0000", width: '78%'}}>
                            {/* Registration */}
                            <View style={{height:'85%', backgroundColor: "#FFFFFF", width: '100%', flexDirection:"column" }}>
                                <View style={{paddingLeft: 20, paddingTop: 0, backgroundColor: "#FFFFFF", width: '100%'}}>
                                  <Text style={[stylesItem.bigTextStyle, {textAlign:'left', textAlignVertical:'center'}]}>Registration</Text>
                                  <View
                                    style={{
                                      paddingTop:5,
                                      width: 50,
                                      borderBottomColor: 'black',
                                      borderBottomWidth: 2,
                                    }}
                                  />
                                </View>
                                <View style={{paddingLeft: 20, paddingTop: 10, backgroundColor: "#FFFFFF", width: '100%'}}>
                                    <Text style={[stylesItem.textStyle, {fontSize: '1rem', textAlign:'left', textAlignVertical:'center', paddingBottom:5}]}>Ordinal Id</Text>
                                    <TextInput
                                          style={{fontFamily:'ShareTechMono', height: 30, fontSize:'1.2rem', backgroundColor: 'black', width: "96%",fontWeight:'bold', color: 'lightgray', borderWidth: 0,  marginBottom: 4 }}
                                          onChangeText={setSaveOrdId}
                                          placeholder="Enter the Ordinal Id"/>
                                </View>
                                {/* <View style={{paddingLeft: 20, paddingTop: 0, backgroundColor: "#FFFFFF", width: '100%'}}>
                                    <Text style={[stylesItem.textStyle, {fontSize: '1rem', textAlign:'left', textAlignVertical:'center', paddingBottom:5}]}>Address</Text>
                                    <TextInput
                                          style={{height: 30, fontSize:'1.2rem', backgroundColor: 'black', width: "96%",fontWeight:'bold', color: 'lightgray', borderWidth: 0,  marginBottom: 4 }}
                                          onChangeText={setSaveAddress}
                                          placeholder="Enter the address"/>
                                </View>
                                <View style={{paddingLeft: 20, paddingTop: 0, backgroundColor: "#FFFFFF", width: '100%'}}>
                                    <Text style={[stylesItem.textStyle, {fontSize: '1rem', textAlign:'left', textAlignVertical:'center', paddingBottom:5}]}>Transaction Id</Text>
                                    <TextInput
                                          style={{height: 30, fontSize:'1.2rem', backgroundColor: 'black', width: "96%",fontWeight:'bold', color: 'lightgray', borderWidth: 0,  marginBottom: 4 }}
                                          onChangeText={setSaveTransId}
                                          placeholder="Enter the transaction Id"/>
                                </View>
                                <View style={{paddingLeft: 20, paddingTop: 0, backgroundColor: "#FFFFFF", width: '100%'}}>
                                    <Text style={[stylesItem.textStyle, {fontSize: '1rem', textAlign:'left', textAlignVertical:'center', paddingBottom:5}]}>Transaction Timestamp (format: yyyy-mm-dd hh:mm:ss)</Text>
                                    <TextInput
                                          style={{height: 30, fontSize:'1.2rem', backgroundColor: 'black', width: "96%",fontWeight:'bold', color: 'lightgray', borderWidth: 0,  marginBottom: 4 }}
                                          onChangeText={setSavetimestamp}
                                          placeholder="Enter the transaction timestamp"/>
                                </View> */}
                                <View style={{paddingLeft: 20, paddingTop: 0, backgroundColor: "#FFFFFF", width: '100%'}}>
                                    <Text style={[stylesItem.textStyle, {fontSize: '1rem', textAlign:'left', textAlignVertical:'center', paddingBottom:5}]}>Discord Id</Text>
                                    <TextInput
                                          style={{fontFamily:'ShareTechMono', height: 30, fontSize:'1.2rem', backgroundColor: 'black', width: "96%",fontWeight:'bold', color: 'lightgray', borderWidth: 0,  marginBottom: 4 }}
                                          onChangeText={setSaveDiscordId}
                                          placeholder="Enter your Discord Id"/>
                                </View>
                                <View style={{paddingLeft: 20, paddingTop: 0, backgroundColor: "#FFFFFF", width: '100%'}}>
                                    <Text style={[stylesItem.textStyle, {fontSize: '1rem', textAlign:'left', textAlignVertical:'center', paddingBottom:5}]}>Twitter Id (optional)</Text>
                                    <TextInput
                                          style={{fontFamily:'ShareTechMono', height: 30, fontSize:'1.2rem', backgroundColor: 'black', width: "96%",fontWeight:'bold', color: 'lightgray', borderWidth: 0,  marginBottom: 4 }}
                                          onChangeText={setSaveTwitterId}
                                          placeholder="Enter your Twitter Id"/>
                                </View>                                

                            </View>
                            
                            <View style={{height:'15%', backgroundColor: "#FFFFFF", width: '100%', flexDirection:"row"}}>
                                <View style={{paddingLeft: 20, height:'100%', backgroundColor: "#FFFFFF", width: '50%', alignItems:'flex-start' }}>
                                  <TouchableWithoutFeedback
                                    style={[stylesItem.button]}
                                    onPress={() => cancelRegistrationPanel()}>
                                    <View style={[stylesItem.button,{color:'black', cursor:'pointer', width:60, height:60, alignItems:'center', justifyContent:'flex-end', backgroundColor:'#FFF', borderWidth:2, borderColor: "#252525"}]}>
                                      <Text style={[stylesItem.bigTextStyle,{}]}>no</Text>
                                    </View>
                                  </TouchableWithoutFeedback>
                                </View>
                                <View style={{paddingRight: 20, height:'100%', backgroundColor: "#FFFFFF", width: '50%', alignItems:'flex-end' }}>
                                  <TouchableWithoutFeedback
                                    style={[stylesItem.button]}
                                    onPress={() => checkTransaction()}>
                                    <View style={[stylesItem.button,{color:'black', cursor:'pointer', width:60, height:60, alignItems:'center', justifyContent:'flex-end', backgroundColor:'#FFF', borderWidth:2, borderColor: "#252525"}]}>
                                      <Text style={[stylesItem.bigTextStyle,{}]}>go</Text>
                                    </View>
                                  </TouchableWithoutFeedback>
                                </View>
                            </View>
                        </View>
                        

                      </View>    
                    </View>
                  </View>
                </Modal> 

      )

    }

    function cancelDataViewPanel() {
        setSelectedItemForDataView('');
        setDataViewPanelVisible(false);
    }

    //<Text style={[stylesItem.textStyle, {fontSize: '1rem', textAlign:'left', textAlignVertical:'center', paddingBottom:5}]}>Twitter Id (optional)</Text>

    const renderOrdinalItem = ({ item, index }) => (
      
      <View key={index} style={{height:80, flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
          <View style={{height:35, flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
            <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#FFFFFF", width: '8%'}}>
                  <TextInput
                      style={{fontFamily:'ShareTechMono', height: 30, fontSize:(isDesktopLandscape) ? 20 : 12, textAlign:'center',  backgroundColor: (item.item_verified>0 ? 'white' : 'darkgrey'), width: "99%",fontWeight:'bold', color: 'black', borderWidth: 3,  marginBottom: 0 }}
                      value={index + 1}
                      editable = {false}
                      placeholder={index + 1}/>
              </View>    
              <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#FFFFFF", width: '15%'}}>
                  <TextInput
                      style={{fontFamily:'ShareTechMono', height: 30, fontSize:(isDesktopLandscape) ? 20 : 12, textAlign:'center', backgroundColor: (item.item_verified>0 ? 'white' : 'darkgrey'), width: "99%",fontWeight:'bold', color: 'black', borderWidth: 3,  marginBottom: 0 }}
                      value={'#' + item.item_rank}
                      editable = {false}
                      placeholder={'#' + item.item_rank}/>
              </View>
              <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#FFFFFF", width: '57%'}}>
                  <TextInput
                      style={{fontFamily:'ShareTechMono', textAlign:'center', paddingLeft:3, height: 30, fontSize:(isDesktopLandscape) ? 20 : 10,  backgroundColor: (item.item_verified>0 ? 'white' : 'darkgrey'), width: "99%",fontWeight:'bold', color: 'black', borderWidth: 3,  marginBottom: 0 }}
                      value={item.item_timestamp + ' ' + item.item_timestamp_timezone}
                      editable = {false}
                      placeholder={item.item_timestamp + ' ' + item.item_timestamp_timezone}/>
              </View>    
              
            
              <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: (item.item_verified>0 ? 'white' : 'darkgrey'), width: '20%'}}>
                  <View style={{cursor:'pointer', width: '99%', height: 30, alignItems:'center', justifyContent:'center', backgroundColor: (item.item_verified>0 ? 'white' : 'darkgrey'), borderWidth: 3, borderRadius:5}}>
                    <Text style={{fontFamily:'ShareTechMono', color: 'grey', cursor:'pointer',  fontSize: (isDesktopLandscape) ? 20 : 12}}
                            onPress={() => Linking.openURL('https://ordinals.com/inscription/' + item.item_ordinal)}> {(isDesktopLandscape) ? "Ordinals⇨ " : "Ord⇨ "}
                    </Text>
                  </View>
            </View>
        </View >
        <View style={{paddingBottom: 10, width: '100%', height:40, flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
            {(isDesktopLandscape) ?
            <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '8%'}}>
                  <TextInput
                      style={{fontFamily:'ShareTechMono', height: 30, fontSize:(isDesktopLandscape) ? 20 : 12, textAlign:'center',  backgroundColor: '#0000', width: "99%",fontWeight:'bold', color: 'black', borderWidth: 0,  marginBottom: 0 }}
                      value={'Addr'}
                      editable = {false}
                      placeholder={'Addr'}/>
              </View> 
              : ""}   
              <View style={{paddingTop: 0, justifyContent:'center', backgroundColor: "#FFFFFF", width: (isDesktopLandscape) ? '92%' : "100%"}}>
                  <TextInput
                      style={{fontFamily:'ShareTechMono', height: 30, fontSize:(isDesktopLandscape) ? 20 : 9, textAlign:'center', backgroundColor: (item.item_verified>0 ? 'white' : 'darkgrey'), width: "100%",fontWeight:'bold', color: 'black', borderWidth: 3,  marginBottom: 0 }}
                      value={item.item_address}
                      editable = {false}
                      placeholder={item.item_address}/>
              </View>
        </View >
      </View>
    );


    function renderDataViewPanel () {

      return (

        <Modal visible={isDataViewPanelVisible}
                  animationType="slide"
                  transparent={true}>
                  <View style={stylesItem.centeredView}>
                    <View style={[stylesItem.modalView, {padding: (isDesktopLandscape) ? 35 : 15, flexDirection:'column', width: (isDesktopLandscape) ? 1000 : "100%"}]}>
                      
                      <View style={{width:  (isDesktopLandscape) ? 1000 : '100%', height: 500, backgroundColor: "#0000", width: '100%', flexDirection: "row"}}>

                          <View style={{height: 500, backgroundColor: "#0000", width: '8%' , alignItems: 'center'}}>
                            <TouchableWithoutFeedback
                                style={[stylesItem.button, stylesItem.buttonClose]}
                                onPress={() => cancelDataViewPanel()}>
                                <View style={{cursor:'pointer', height: 50, backgroundColor: "#252525", width: '100%', borderWidth:3, borderColor:"white", alignItems:'center', justifyContent:'center'}}>
                                    <Text style={[stylesItem.bigTextStyleWhite, {fontSize:  (isDesktopLandscape) ? 20 : 15}]}>X</Text>
                                </View>
                            </TouchableWithoutFeedback>
                          </View>
                          
                          <View style={{paddingLeft: 2, height: 500, backgroundColor: "#0000", width: (isDesktopLandscape) ? '14%' : '10%' , alignItems: 'center', justifyContent:'flex-start'}}>
                              <View style={{height: 50, backgroundColor: "#FFFFFF", width: '100%', alignItems:'center', justifyContent:'center'}}>
                                <Text style={[stylesItem.bigTextStyle, {fontSize:  (isDesktopLandscape) ? 20 : 12}]}>{selectedItemForDataView.btc_ordinal}</Text>
                              </View>
                          </View>
                          
                          <View style={{paddingLeft: 2, height: 500, backgroundColor: "#0000", width: (isDesktopLandscape) ? '78%' : '82%'}}>
                              {/* DataView */}
                              <View style={{height:'85%', backgroundColor: "#FFFFFF", width: '100%', flexDirection:"column" }}>
                                  <View style={{paddingLeft: 5, paddingTop: 5, backgroundColor: "#FFFFFF", width: '100%'}}>
                                    <Text style={[stylesItem.bigTextStyle, {fontSize:  (isDesktopLandscape) ? 20 : 15, paddingBottom:5, textAlign:'left', textAlignVertical:'center'}]}>Registered ordinals</Text>
                                    <TextInput
                                        value={searchDataView}
                                        style={{fontFamily:'ShareTechMono', backgroundColor: "#fff", padding: 5, width:'98%', borderWidth:2,  }}
                                        color="#1B152D"
                                        keyboardType='email-address'
                                        autoCapitalize='none'
                                        placeholder='search'
                                        //accessoryRight={renderIcon}
                                        onChangeText={nextValue => setSearchDataView(nextValue)}
                                    /> 
                                    {/* <View
                                      style={{
                                        paddingTop:5,
                                        width: 50,
                                        borderBottomColor: 'black',
                                        borderBottomWidth: 2,
                                      }} 
                                    />*/}
                                  </View>
                                  <View style={{height: 350, paddingLeft: 5, paddingTop: 10, backgroundColor: "#FFFFFF", width: '98%'}}>

                                            <FlatList
                                              style={{ backgroundColor: "#f1f1f1", padding:5, flex:1 }}
                                              data={itemData.filter((p) => (p && p.searchfield) && p.searchfield.toLowerCase() ? p.searchfield.toLowerCase().includes(searchDataView.toLowerCase()) : "")}
                                              //data={itemData}
                                              renderItem={renderOrdinalItem}
                                              keyExtractor={(item,index)=> index.toString()}
                                            />
                                            

                                  </View>
                              </View>
                              
                              <View style={{height:'15%', backgroundColor: "#FFFFFF", width: '100%', flexDirection:"row"}}>
                                  <View style={{paddingLeft: 5, height:'100%', backgroundColor: "#FFFFFF", width: '50%', alignItems:'flex-start' }}>
                                    <TouchableWithoutFeedback
                                      style={[stylesItem.button]}
                                      onPress={() => cancelDataViewPanel()}>
                                      <View style={[stylesItem.button,{color:'black', cursor:'pointer', width:60, height:60, alignItems:'center', justifyContent:'flex-end', backgroundColor:'#FFF', borderWidth:2, borderColor: "#252525"}]}>
                                        <Text style={[stylesItem.bigTextStyle,{}]}>ok</Text>
                                      </View>
                                    </TouchableWithoutFeedback>
                                  </View>
                                  {/* <View style={{paddingRight: 20, height:'100%', backgroundColor: "#FFFFFF", width: '50%', alignItems:'flex-end' }}>
                                    <TouchableWithoutFeedback
                                      style={[stylesItem.button]}
                                      onPress={() => saveTransaction()}>
                                      <View style={[stylesItem.button,{color:'black', cursor:'pointer', width:60, height:60, alignItems:'center', justifyContent:'flex-end', backgroundColor:'#FFF', borderWidth:2, borderColor: "#252525"}]}>
                                        <Text style={[stylesItem.bigTextStyle,{}]}>go</Text>
                                      </View>
                                    </TouchableWithoutFeedback>
                                  </View> */}
                              </View>
                          </View>
                        
                        

                      </View>

                     

                    </View>
                  </View>
                </Modal> 

      )

    }


    function setFilteredItemSet(text) {
        setOffset(1);
        setFilteredItem(text);
    }

    const renderSearch = () => {
      return <View style={{width:(isDesktopLandscape) ? 200 : '100%',backgroundColor: "#252525", height: (isDesktopLandscape) ? 60 : 50, flexDirection: "column", alignItems: 'center', justifyContent: 'center'}}>
                <View style={{width: (isDesktopLandscape) ? '100%' : '50%',backgroundColor: "#252525", height: (isDesktopLandscape) ? 30 : 25, flexDirection: "row", alignItems: 'center', justifyContent: 'center'}}>
                    <TextInput  placeholder="search" value={filteredItem} onChangeText={setFilteredItemSet} style={{fontFamily:'ShareTechMono', textAlign:'center', flex:1, height:(isDesktopLandscape) ? 30 : 25, backgroundColor: 'white', borderWidth: 2, color:'#000', borderColor: "#FFF", borderRadius: 20}} />
                    <TouchableWithoutFeedback onPress={() => {
                              setOffset(1);
                              setFilteredItem('');
                            }}>
                            <View style={[stylesFaq.smallbutton,{cursor:'pointer', width:55, height:(isDesktopLandscape) ? 30 : 25, alignItems:'center', justifyContent:'center', backgroundColor:'#252525', borderWidth:2, borderColor: "#fff", borderRadius:20}]}>
                              <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: "#FFFFFF" }}>Clear</Text>
                          </View>
                    </TouchableWithoutFeedback>
                </View>
                <View style={{paddingTop: 10, width:'100%',backgroundColor: "#252525", height: (isDesktopLandscape) ? 30 : 15}}>
                      <Text style={[stylesItem.textStyleWhite,{fontWeight:'normal', fontSize: 10}]}>(supports ranges, like '1000-2000')</Text>
                </View>
            </View>
    }

    const renderSoldout = () => {
        return <Image source={(require('./img/INSCRIBED.png'))} style={{ resizeMode:'stretch', position:'absolute', left: (isDesktopLandscape) ? -30 : -8, top:(isDesktopLandscape) ? -30 : -15, height:(isDesktopLandscape) ? 84 : 36, width:(isDesktopLandscape) ? 110 : 48}} ></Image>;
    }
    const renderSoldoutPici = () => {
      return <Image source={(require('./img/INSCRIBED.png'))} style={{ resizeMode:'stretch', position:'absolute', left: (isDesktopLandscape) ? -25 : -14, top:(isDesktopLandscape) ? -20 : -10, height:(isDesktopLandscape) ? 48 : 28, width:(isDesktopLandscape) ? 72 : 42}} ></Image>;
  }

    // width={ScreenWidth} height={ScreenHeight}
  return (
    <View style={styles.container}>
      <ImageBackground source={require('./img/btcdgts-parallax.png')}  resizeMode='cover' style={{width: "100%" , justifyContent: 'center'}}>
      
      {renderAdminPass()}
      
          {/* <View style={{paddingLeft:(isDesktopLandscape) ? 175 : 0, height:60,backgroundColor: "#252525", width: '100%', flexDirection: "row" , alignItems: 'center'}}> */}
          <View style={{height:60,backgroundColor: "#252525", width: '100%' , alignItems: 'center'}}>
            <View style={{height:60,backgroundColor: "#252525", width: '90%', flexDirection: "row" , alignItems: 'center'}}>
                <View style={{zIndex: '99 !important',minWidth: 40, width:'10%',backgroundColor: "#252525", height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                {/* <View style={{zIndex: '99 !important', paddingLeft:(isDesktopLandscape) ? 100 : 0,minWidth:170, width:'10%',backgroundColor: "#252525", height: '100%', alignItems: 'center', justifyContent: 'center'}}> */}
                    <Image source={require('./img/logo_white_trans.png')} style={{resizeMode:'stretch', height:(isDesktopLandscape) ? 25 : 16, width:(isDesktopLandscape) ? 70 : 46}} ></Image> 
                </View>
                <View style={{paddingLeft: 5, width:'50%',backgroundColor: "#252525", height: '100%', alignItems: 'flex-start', justifyContent: 'center'}}>
                    <TouchableWithoutFeedback onPress={() => {
                          //console.log('x:' + xHeld, 'y:' + yHeld, 'control:' + controlHeld)
                                if (xHeld && controlHeld && yHeld) {
                                  setOpenAdminPass();
                                } 
                            }}>
                    
                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 40 : 20, color:  '#FFFFFF' }}>Bitcoindigits</Text>
                        {/* <img src={bitcoindigits_svg} width={220} /> */}
                    </TouchableWithoutFeedback>
                </View>
                <View style={{width:'40%',backgroundColor: "#0000", height: '100%', flexDirection: "row", alignItems: 'center', justifyContent:'flex-end'}}>
                  
                  {renderAdmin()}
    
                  <TouchableWithoutFeedback onPress={() => {
                              toggleAboutModal();
                          }}>
                          <Text style={[styles.faq,{fontSize: (isDesktopLandscape) ? 20 : 12}]}>About  </Text>
                    </TouchableWithoutFeedback>
    
                      {renderAbout()}
    
                  <TouchableWithoutFeedback onPress={() => {
                              toggleFaqModal();
                          }}>
                          <Text style={[styles.faq,{paddingLeft:3,fontSize: (isDesktopLandscape) ? 20 : 12}]}>FAQ  </Text>
                    </TouchableWithoutFeedback>
    
                      {renderFaq()}
                      
                      <TouchableWithoutFeedback onPress={() => {
                          Linking.openURL('https://magiceden.io/ordinals/marketplace/bitcoin-digits');
                      }}>
                        <View style={{cursor:'pointer', paddingTop:(isDesktopLandscape) ? 7 : 4, paddingRight:(isDesktopLandscape) ? 10 : 0, paddingLeft:(isDesktopLandscape) ? 10 : 5, height:(isDesktopLandscape) ? 40 : 25,backgroundColor: "#0000", width: (isDesktopLandscape) ? 40 : 25 , alignItems: 'center', justifyContent:'center'}}>
                        <img src={meden_icon}   height={(isDesktopLandscape) ? 40 : 25} width={(isDesktopLandscape) ? 40 : 25} />
                          </View>
                      </TouchableWithoutFeedback>

                      <TouchableWithoutFeedback onPress={() => {
                          Linking.openURL('https://www.okx.com/web3/marketplace/nft/collection/btc/bitcoin-digits-1');
                      }}>
                        <View style={{cursor:'pointer', paddingRight:(isDesktopLandscape) ? 10 : 0, paddingLeft:(isDesktopLandscape) ? 10 : 5, height:(isDesktopLandscape) ? 35 : 20,backgroundColor: "#0000", width: (isDesktopLandscape) ? 35 : 20 , alignItems: 'center'}}>
                        <img src={okx_icon}   height={(isDesktopLandscape) ? 35 : 20} width={(isDesktopLandscape) ? 35 : 20} />
                          </View>
                      </TouchableWithoutFeedback>

                      <TouchableWithoutFeedback onPress={() => {
                          Linking.openURL('https://twitter.com/BitcoinDigits');
                      }}>
                        <View style={{cursor:'pointer', paddingLeft:(isDesktopLandscape) ? 10 : 2, height:(isDesktopLandscape) ? 30 : 20,backgroundColor: "#0000", width: (isDesktopLandscape) ? 30 : 20 , alignItems: 'center'}}>
                        <img src={twitter_icon_svg}   height={(isDesktopLandscape) ? 30 : 20} width={(isDesktopLandscape) ? 30 : 20} />
                          </View>
                      </TouchableWithoutFeedback>
                      
                      
                      <Text style={styles.title}>  </Text>
                      {/*}<Image source={require('./img/twitter-icon.png')} style={{height:30, width:30}} ></Image>  */}
                      
                      <TouchableWithoutFeedback onPress={() => {
                          Linking.openURL('https://discord.gg/VddAANnq5g');
                      }}>
                        <View style={{cursor:'pointer', height:(isDesktopLandscape) ? 30 : 20,backgroundColor: "#0000", width: (isDesktopLandscape) ? 30 : 20 , alignItems: 'center'}}>
                              <img src={discord_icon_svg} height={(isDesktopLandscape) ? 30 : 20} width={(isDesktopLandscape) ? 30 : 20} />
                          </View>
                      </TouchableWithoutFeedback>
                      
                        
                  </View>
              
            </View>
          </View>

          <View style={{height: ScreenHeight - 120}} >
              <ScrollView style={{backgroundColor: "#0000", width: '100%'}}>


                  {/* <View style={{height:0,backgroundColor: "#0000", width: '100%' , alignItems: 'center'}}>
                      <img src={lobtcdgtsgo_svg}   />
                  
                  </View> */}
                  
                {/*  <View style={{height:30,backgroundColor: "#252525", width: '100%' , alignItems: 'center'}}>
                    <Text style={styles.title}>The first digits to crawl into the bitcoin network...</Text>
                    </View>
        */}
                  <View style={{height: (isDesktopLandscape) ? 300 : 150,backgroundColor: "#0000", width: '100%' , alignItems: 'center'}}>

                  </View>

                    {renderRegistrationPanel()}
                    {renderDataViewPanel()}
                    
               {/*    <View style={{paddingBottom:0, height:110, width:'70%', backgroundColor: "#252525", width: '100%', alignItems: 'center', justifyContent:'center'}}>
                      <Text style={[styles.abouttext,{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 20 : 10 , color:  'red' }]}>Beware! There is an unverified copy colection of Bitcoin Digits on OKX (and also an old unused one) ! (Please do not buy until the case is cleared, we are in contact with OKX) scammers even copied our logo and website design to their discord.</Text>
                  </View> */}
                  <View style={{paddingBottom:20,height:(isDesktopLandscape) ? 170 : 150, width:'70%', backgroundColor: "#252525", width: '100%', alignItems: 'center', justifyContent:'center'}}>
                      <Text style={[styles.abouttext, {fontSize: (isDesktopLandscape) ? 20 : 10}]}>WELCOME TO THE BITCOIN DIGITS
            The Bitcoin Digits inscription(NFT) collection containing the very first and earliest registered digits in Ordinals. The collection is positioned to become one of the first projects to make a significant impact on the market. This means that some digits of the collection will have a sub 1k inscription, which is really f….ng low. This speaks to the level of engagement and enthusiasm within the community, as well as the quality and uniqueness of the project being offered.</Text>

                  </View>
                  
                  <View style={{height:(isDesktopLandscape) ? 60 : 30,backgroundColor: "#252525", width: '100%', flexDirection: "row" , alignItems: 'center', justifyContent:'space-evenly'}}>

                      <TouchableWithoutFeedback onPress={() => {
                                setOffset(1);
                                setFilteredItem('');
                                setCollectionType(1);
                                setCollectionIsPoker(0);
                            }}>
                            <View style={[stylesFaq.button,{cursor:'pointer', width:(isDesktopLandscape) ? 180 : 70, height:(isDesktopLandscape) ? 60 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderColor: (collectionType == 1 ? '#FFFFFF' : '#696969')}]}>
                              {renderSoldout()}
                              <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 15, color:  (collectionType == 1 ? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? '1D BTC club' : '1D'}</Text>
                          </View>
                      </TouchableWithoutFeedback>

                      <TouchableWithoutFeedback onPress={() => {
                                setOffset(1);
                                setFilteredItem('');
                                setCollectionType(2);
                                setCollectionIsPoker(0);
                            }}>
                            <View style={[stylesFaq.button, {cursor:'pointer', width:(isDesktopLandscape) ? 180 : 70, height:(isDesktopLandscape) ? 60 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderColor: (collectionType == 2 ? '#FFFFFF' : '#696969')}]}>
                            {/* <View onMouseEnter={MouseEnterBtn} onMouseLeave={onMouseLeaveBtn} style={[stylesFaq.button, stylesFaq.buttonClose,{cursor:'pointer', width:150, height:60, alignItems:'center', justifyContent:'center', backgroundColor:'#000', borderWidth:1.5, borderColor: "#fff"}]}> */}
                            {renderSoldout()}
                              <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 15, color:  (collectionType == 2 ? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? '2D BTC club' : '2D'}</Text>
                          </View>
                      </TouchableWithoutFeedback>

                      <TouchableWithoutFeedback onPress={() => {
                                setOffset(1);
                                setFilteredItem('');
                                setCollectionType(3);
                                setCollectionIsPoker(0);
                            }}>
                            <View style={[stylesFaq.button,{cursor:'pointer', width:(isDesktopLandscape) ? 180 : 70, height:(isDesktopLandscape) ? 60 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderColor: (collectionType == 3 ? '#FFFFFF' : '#696969')}]}>
                            {renderSoldout()}
                              <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 15, color:  (collectionType == 3 ? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? '3D BTC club' : '3D'}</Text>
                          </View>
                      </TouchableWithoutFeedback>

                      <TouchableWithoutFeedback onPress={() => {
                                setOffset(1);
                                setFilteredItem('');
                                setCollectionType(4);
                            }}>
                            <View style={[stylesFaq.button,{cursor:'pointer', width:(isDesktopLandscape) ? 180 : 70, height:(isDesktopLandscape) ? 60 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderColor: (collectionType == 4 ? '#FFFFFF' : '#696969')}]}>
                            {renderSoldout()}
                              <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 15, color: (collectionType == 4 ? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? '4D BTC club' : '4D'}</Text>
                          </View>
                      </TouchableWithoutFeedback>

                      <TouchableWithoutFeedback onPress={() => {
                                setOffset(1);
                                setFilteredItem('');
                                setCollectionType(5);
                            }}> 
                            <View style={[stylesFaq.button,{cursor:'pointer', width:(isDesktopLandscape) ? 180 : 70, height:(isDesktopLandscape) ? 60 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderColor: (collectionType == 5 ? '#FFFFFF' : '#696969')}]}>
                              <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 15, color: (collectionType == 5 ? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? '5D BTC club' : '5D'}</Text>
                          </View>
                      </TouchableWithoutFeedback>

{/*                       <TouchableWithoutFeedback onPress={() => {
                                setOffset(1);
                                setFilteredItem('');
                                setCollectionType(6);
                            }}>
                            <View style={[stylesFaq.button,{cursor:'pointer', width:(isDesktopLandscape) ? 140 : 50, height:(isDesktopLandscape) ? 60 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderColor: (collectionType == 6 ? '#FFFFFF' : '#696969')}]}>
                              <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (collectionType == 6 ? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? 'PRIME BTC club' : 'PRIME'}</Text>
                          </View>
                      </TouchableWithoutFeedback>

                      <TouchableWithoutFeedback onPress={() => {
                                setOffset(1);
                                setFilteredItem('');
                                setCollectionType(7);
                            }}>
                            <View style={[stylesFaq.button,{cursor:'pointer', width:(isDesktopLandscape) ? 140 : 50, height:(isDesktopLandscape) ? 60 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderColor: (collectionType == 7 ? '#FFFFFF' : '#696969')}]}>
                              <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (collectionType == 7 ? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? 'FIBO BTC club' : 'FIBO'}</Text>
                          </View>
                      </TouchableWithoutFeedback> */}

                      {(isDesktopLandscape) ?
                        renderSearch()
                      :""}
                  </View>


                  <View style={{height:(isDesktopLandscape) ? 60 : 40,backgroundColor: "#252525", width: '100%', flexDirection: "row" , alignItems: 'center', justifyContent:'space-evenly'}}>

                    <TouchableWithoutFeedback onPress={() => {
                              setOffset(1);
                              setFilteredItem('');
                              setCollectionIsPrime(currentState => currentState === 0 ? 1 : 0);
                          }}>
                          <View style={[stylesFaq.button,{cursor:'pointer', width:(isDesktopLandscape) ? 100 : 50, height:(isDesktopLandscape) ? 30 : 20, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderColor: (collectionIsPrime == 1 ? '#FFFFFF' : '#696969')}]}>
                            <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 12 : 8, color: (collectionIsPrime == 1 ? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? 'PRIME' : 'PRIME'}</Text>
                        </View>
                    </TouchableWithoutFeedback>

                    <TouchableWithoutFeedback onPress={() => {
                              setOffset(1);
                              setFilteredItem('');
                              setCollectionIsFibo(currentState => currentState === 0 ? 1 : 0);
                          }}>
                          <View style={[stylesFaq.button,{cursor:'pointer', width:(isDesktopLandscape) ? 100 : 50, height:(isDesktopLandscape) ? 30 : 20, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderColor: (collectionIsFibo == 1 ? '#FFFFFF' : '#696969')}]}>
                            {renderSoldoutPici()}
                            <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 12 : 8, color: (collectionIsFibo == 1 ? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? 'FIBO' : 'FIBO'}</Text>
                        </View>
                    </TouchableWithoutFeedback>
                    <TouchableWithoutFeedback onPress={() => {
                              setOffset(1);
                              setFilteredItem('');
                              setCollectionIsPalindrome(currentState => currentState === 0 ? 1 : 0);
                          }}>
                          <View style={[stylesFaq.button,{cursor:'pointer', width:(isDesktopLandscape) ? 100 : 50, height:(isDesktopLandscape) ? 30 : 20, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderColor: (collectionIsPalindrome == 1 ? '#FFFFFF' : '#696969')}]}>
                              {renderSoldoutPici()}
                              <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 12 : 8, color: (collectionIsPalindrome == 1 ? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? 'PALINDROME' : 'PALINDROME'}</Text>
                        </View>
                    </TouchableWithoutFeedback>
                    <TouchableWithoutFeedback onPress={() => {
                              setOffset(1);
                              setFilteredItem('');
                              setCollectionIsPoker(currentState => currentState === 0 ? 1 : 0);
                              setCollectionType(5);
                          }}>
                          <View style={[stylesFaq.button,{cursor:'pointer', width:(isDesktopLandscape) ? 100 : 50, height:(isDesktopLandscape) ? 30 : 20, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderColor: (collectionIsPoker == 1 ? '#FFFFFF' : '#696969')}]}>
                            {renderSoldoutPici()}
                            <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 12 : 8, color: (collectionIsPoker == 1 ? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? 'POKER' : 'POKER'}</Text>
                        </View>
                    </TouchableWithoutFeedback>
          
                  </View>

                  {(!isDesktopLandscape) ?
                        renderSearch()
                      :""}
                  
                  <View style={{paddingTop:(isDesktopLandscape) ? 20 : 10, backgroundColor: "#252525", width: '100%', alignItems: 'center', justifyContent:'space-evenly'}}>
                      <View style={[styles.app, {paddingBottom: 10, width: (isDesktopLandscape) ? 800 : "80%"}]}>
                        <Text style={[styles.title, {fontSize: (isDesktopLandscape) ? 20 : 12}]}>{filteredItem!='' ? 'Search result (' + filteredItem +  ')' : collectionType + 'D BTC club'}</Text>
                        <View style={{width:'100%', height:(isDesktopLandscape) ? 80 : 50,backgroundColor: "#252525", flexDirection: "row" , alignItems: 'center', justifyContent:'center'}}>
                            <View style={{width: (isDesktopLandscape) ? '45%' : '35%',backgroundColor: "#252525", height: '100%', flexDirection: "row" , alignItems: 'center', justifyContent: 'center'}}>
                                    {(isDesktopLandscape) ? <Text style={{ fontFamily:'ShareTechMono', fontSize: 14, color: "#FFF" }}>Filter by: </Text> :""}
                                    <TouchableWithoutFeedback onPress={() => {
                                            setOffset(1);
                                            setFilterType('Registered');
                                        }}>
                                        <View style={[stylesFaq.smallbutton,{cursor:'pointer', width: (isDesktopLandscape) ? 90 : 30, height:(isDesktopLandscape) ? 40 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderWidth:1.5, borderColor: (filterType == 'Registered'? '#FFFFFF' : '#696969')}]}>
                                        {/* <View onMouseEnter={MouseEnterBtn} onMouseLeave={onMouseLeaveBtn} style={{cursor:'pointer', width:80, height:40, alignItems:'center', justifyContent:'center', backgroundColor:'#000', borderWidth:1.5, borderColor: "#fff"}}> */}
                                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (filterType == 'Registered'? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? 'Registered' : 'Reg'}</Text>
                                      </View>
                                  </TouchableWithoutFeedback>
                                  <TouchableWithoutFeedback onPress={() => {
                                            setOffset(1);
                                          // setSubType('All');
                                            setFilterType('Empty');
                                        }}>
                                        <View style={[stylesFaq.smallbutton,{cursor:'pointer', width:(isDesktopLandscape) ? 80 : 30, height:(isDesktopLandscape) ? 40 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderWidth:1.5, borderColor: (filterType == 'Empty'? '#FFFFFF' : '#696969')}]}>
                                        {/* <View onMouseEnter={MouseEnterBtn} onMouseLeave={onMouseLeaveBtn} style={{cursor:'pointer', width:80, height:40, alignItems:'center', justifyContent:'center', backgroundColor:'#000', borderWidth:1.5, borderColor: "#fff"}}> */}
                                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (filterType == 'Empty'? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? 'Empty' : 'Emp'}</Text>
                                      </View>
                                  </TouchableWithoutFeedback>
                                  <TouchableWithoutFeedback onPress={() => {
                                            setOffset(1);
                                            setFilterType('All');
                                        }}>
                                          <View style={[stylesFaq.smallbutton,{cursor:'pointer', width:(isDesktopLandscape) ? 80 : 30, height:(isDesktopLandscape) ? 40 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderWidth:1.5, borderColor: (filterType == 'All'? '#FFFFFF' : '#696969')}]}>
                                        {/* <View onMouseEnter={MouseEnterBtn} onMouseLeave={onMouseLeaveBtn} style={{cursor:'pointer', width:80, height:40, alignItems:'center', justifyContent:'center', backgroundColor:'#000', borderWidth:1.5, borderColor: "#fff"}}> */}
                                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (filterType == 'All'? '#FFFFFF' : '#696969') }}>All</Text>
                                      </View>
                                  </TouchableWithoutFeedback>
                            </View>
                            <View style={{width:'20%',backgroundColor: "#252525", height: '100%', flexDirection: "row" , alignItems: 'center', justifyContent: 'center'}}>
                                    {(isDesktopLandscape) ? <Text style={{fontFamily:'ShareTechMono', fontSize: 14, color: "#FFF" }}>Sort: </Text> : ""}
                                    <TouchableWithoutFeedback onPress={() => {
                                            setOffset(1);
                                            setSortType('Asc');
                                        }}>
                                        <View style={[stylesFaq.smallbutton,{cursor:'pointer', width:(isDesktopLandscape) ? 60 : 30, height:(isDesktopLandscape) ? 40 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderWidth:1.5, borderColor: (sortType == 'Asc'? '#FFFFFF' : '#696969')}]}>
                                        {/* <View onMouseEnter={MouseEnterBtn} onMouseLeave={onMouseLeaveBtn} style={{cursor:'pointer', width:80, height:40, alignItems:'center', justifyContent:'center', backgroundColor:'#000', borderWidth:1.5, borderColor: "#fff"}}> */}
                                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (sortType == 'Asc'? '#FFFFFF' : '#696969') }}>Asc</Text>
                                      </View>
                                  </TouchableWithoutFeedback>
                                  <TouchableWithoutFeedback onPress={() => {
                                            setOffset(1);
                                            setSortType('Desc');
                                        }}>
                                        <View style={[stylesFaq.smallbutton,{cursor:'pointer', width:(isDesktopLandscape) ? 60 : 30, height:(isDesktopLandscape) ? 40 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderWidth:1.5, borderColor: (sortType == 'Desc'? '#FFFFFF' : '#696969')}]}>             
                                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (sortType == 'Desc'? '#FFFFFF' : '#696969') }}>Desc</Text>
                                      </View>
                                  </TouchableWithoutFeedback>
                            </View>
                            <View style={{width: (isDesktopLandscape) ? '35%' : '45%',backgroundColor: "#252525", height: '100%', flexDirection: "row" , alignItems: 'center', justifyContent: 'center'}}>
                                    {(isDesktopLandscape) ? <Text style={{fontFamily:'ShareTechMono', fontSize: 14, color: "#FFF" }}> Sub:  </Text> :""}
                                    <TouchableWithoutFeedback onPress={() => {
                                            setOffset(1);
                                            setFilterType('Registered');
                                            setSubType('10K');
                                        }}>
                                        <View style={[stylesFaq.smallbutton,{cursor:'pointer', width:(isDesktopLandscape) ? 80 : 50, height:(isDesktopLandscape) ? 40 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderWidth:1.5, borderColor: (sortType == '10K'? '#FFFFFF' : '#696969')}]}>
                                        {/* <View onMouseEnter={MouseEnterBtn} onMouseLeave={onMouseLeaveBtn} style={{cursor:'pointer', width:80, height:40, alignItems:'center', justifyContent:'center', backgroundColor:'#000', borderWidth:1.5, borderColor: "#fff"}}> */}
                                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (subType == '10K'? '#FFFFFF' : '#696969') }}>sub10K</Text>
                                      </View>
                                  </TouchableWithoutFeedback>
                                  <TouchableWithoutFeedback onPress={() => {
                                            setOffset(1);
                                            setFilterType('Registered');
                                            setSubType('100K');
                                        }}>
                                        <View style={[stylesFaq.smallbutton,{cursor:'pointer', width:(isDesktopLandscape) ? 80 : 50, height:(isDesktopLandscape) ? 40 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderWidth:1.5, borderColor: (sortType == '100K'? '#FFFFFF' : '#696969')}]}>             
                                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (subType == '100K'? '#FFFFFF' : '#696969') }}>sub100K</Text>
                                      </View>
                                  </TouchableWithoutFeedback>
                                  <TouchableWithoutFeedback onPress={() => {
                                            setOffset(1);
                                            setSubType('All');
                                        }}>
                                        <View style={[stylesFaq.smallbutton,{cursor:'pointer', width:(isDesktopLandscape) ? 80 : 30, height:(isDesktopLandscape) ? 40 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderWidth:1.5, borderColor: (sortType == 'All'? '#FFFFFF' : '#696969')}]}>             
                                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (subType == 'All'? '#FFFFFF' : '#696969') }}>All</Text>
                                      </View>
                                  </TouchableWithoutFeedback>
                            </View>

                        </View>
                        <FlatList
                          data={numbers}
                          numColumns={(isDesktopLandscape) ? 10 : 5}
                          renderItem={renderItem}
                          keyExtractor={(item) => item.id}
                        />
                      </View>
                  </View>
              </ScrollView>
          </View>

          <View style={{height:(isDesktopLandscape) ? 60 : 40,backgroundColor: "#252525", width: '100%', alignItems: 'center', justifyContent:'center'}}>
                  <TouchableWithoutFeedback onPress={() => {
                            loadCollections();
                        }}>
                        <View style={[stylesFaq.button,{cursor:'pointer', width:(isDesktopLandscape) ? 150 : 100, height:(isDesktopLandscape) ? 50 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderWidth:1.5, borderColor: (!hasMoreItemToLoad? '#FFFFFF' : '#696969')}]}>
                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (!hasMoreItemToLoad ? '#FFFFFF' : '#696969') }}>Load More</Text>
                      </View>
                  </TouchableWithoutFeedback>
                  
          </View>
    
      </ImageBackground> 

    </View>

   

  );
}

const stylesImage = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundImage: {
    flex: 1,
    width: '100%', // vagy egy konkrét méret
    height: '100%', // vagy egy konkrét méret
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
  }
});

const stylesFaq = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
    fontFamily:'ShareTechMono'
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#0000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    fontFamily:'ShareTechMono'
  },
  button: {
    borderRadius: 63,
    padding: 10,
    elevation: 2,
    borderWidth:2,
    fontFamily:'ShareTechMono'
  },
  smallbutton: {
    borderRadius: 0,
    padding: 10,
    elevation: 2,
    fontFamily:'ShareTechMono'
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  buttonTextStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily:'ShareTechMono'
  },
  textStyle: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'justify',
    fontSize: '1.3rem',
    fontFamily:'ShareTechMono'
  },
});

const stylesItem = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
    backgroundColor:"#0000"
  },
  modalView: {
    width: 1000,
    height: 500,
    margin: 20,
    backgroundColor: '#0000',
    borderRadius: 0,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    fontFamily:'ShareTechMono'
  },
  modalViewBig: {
    width: 1160,
    height: 700,
    margin: 20,
    backgroundColor: '#0000',
    borderRadius: 0,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    fontFamily:'ShareTechMono',
    backgroundColor: 'white'
  },
  button: {
    borderRadius: 5,
    padding: 10,
    elevation: 2,
    fontFamily:'ShareTechMono'
  },
  smallbutton: {
    borderRadius: 0,
    padding: 10,
    elevation: 2,
    fontFamily:'ShareTechMono'
  },
  smallroundbutton: {
    borderRadius: 5,
    padding: 5,
    elevation: 2,
    fontFamily:'ShareTechMono'
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  buttonTextStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily:'ShareTechMono'
  },
  textStyleWhite: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily:'ShareTechMono'
  },
  textStyle: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily:'ShareTechMono'
  },
  middleTextStyle: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '1rem',
    fontFamily:'ShareTechMono'
  },
  bigTextStyle: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
    textAlignVertical: 'center',
    fontSize: '2rem',
    fontFamily:'ShareTechMono'
  },
  bigTextStyleWhite: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '2rem',
    fontFamily:'ShareTechMono'
  },
  notSoBigTextStyle: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
    textAlignVertical: 'center',
    fontSize: '1.4rem',
    fontFamily:'ShareTechMono'
  },
  notSoBigTextStyleWhite: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    textAlignVertical: 'center',
    fontSize: '1.4rem',
    fontFamily:'ShareTechMono'
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'justify',
    fontSize: '1.3rem',
    fontFamily:'ShareTechMono'
  }
});

const styles = {
  app: {
    flex: 10, // the number of columns you want to devide the screen into
    marginHorizontal: "auto",
    width: 800,
    backgroundColor: "#252525",
    fontFamily:'ShareTechMono',
    FontFace:'ShareTechMono'
  },
  emptyitem: {
    flex: 1,
    maxWidth: "10%", // 100% devided by the number of rows you want
    alignItems: "center",
    cursor:'pointer',
    
    // my visual styles; not important for the grid
    padding: 10,
    backgroundColor: "#252525", //"rgba(249, 180, 45, 0.25)",
    borderWidth: 1.5,
    borderColor: "#fff",
    color: '#fff',
    fontFamily:'ShareTechMono'
  },
  itemregistered: {
    flex: 1,
    maxWidth: "10%", // 100% devided by the number of rows you want
    alignItems: "center",
    cursor:'pointer',
    
    // my visual styles; not important for the grid
    padding: 10,
    backgroundColor: "darkgrey", //"rgba(249, 180, 45, 0.25)",
    borderWidth: 1.5,
    borderColor: "#fff",
    color: '#fff',
    fontFamily:'ShareTechMono'
  },
  itemverified: {
    flex: 1,
    maxWidth: "10%", // 100% devided by the number of rows you want
    alignItems: "center",
    cursor:'pointer',
    
    // my visual styles; not important for the grid
    padding: 10,
    backgroundColor: "white", //"rgba(249, 180, 45, 0.25)",
    borderWidth: 1.5,
    borderColor: "#252525",
    color: '#252525',
    fontFamily:'ShareTechMono'
  },
  emptyitem_small: {
    flex: 1,
    maxWidth: "20%", // 100% devided by the number of rows you want
    alignItems: "center",
    cursor:'pointer',
    
    // my visual styles; not important for the grid
    padding: 10,
    backgroundColor: "#252525", //"rgba(249, 180, 45, 0.25)",
    borderWidth: 1.5,
    borderColor: "#fff",
    color: '#fff',
    fontFamily:'ShareTechMono'
  },
  itemregistered_small: {
    flex: 1,
    maxWidth: "20%", // 100% devided by the number of rows you want
    alignItems: "center",
    cursor:'pointer',
    
    // my visual styles; not important for the grid
    padding: 10,
    backgroundColor: "darkgrey", //"rgba(249, 180, 45, 0.25)",
    borderWidth: 1.5,
    borderColor: "#fff",
    color: '#fff',
    fontFamily:'ShareTechMono'
  },
  itemverified_small: {
    flex: 1,
    maxWidth: "20%", // 100% devided by the number of rows you want
    alignItems: "center",
    cursor:'pointer',
    
    // my visual styles; not important for the grid
    padding: 10,
    backgroundColor: "white", //"rgba(249, 180, 45, 0.25)",
    borderWidth: 1.5,
    borderColor: "#252525",
    color: '#252525',
    fontFamily:'ShareTechMono'
  },
  container: {
    flex: 1,
    display: 'block',
    height: ScreenHeight,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#252525',
    fontFamily:'ShareTechMono'
  
  },
  header: {
    padding: 20,
    width:'100%'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginVertical: '0em',
    textAlign: 'center',
    color:'white',
    fontFamily:'ShareTechMono'
  },
  faq: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginVertical: '0em',
    textAlign: 'center',
    color:'white',
    cursor: 'pointer',
    fontFamily:'ShareTechMono'
  },
  abouttext: {
    width: '70%',
    fontWeight: 'normal',
    fontSize: '1.2rem',
    marginVertical: '0em',
    textAlign: 'justify',
    color:'white',
    fontFamily:'ShareTechMono'
  },
  text: {
    lineHeight: '1.5em',
    fontSize: '1.125rem',
    marginVertical: '1em',
    textAlign: 'center',
    fontFamily:'ShareTechMono'
  },
  link: {
    color: '#1B95E0'
  },
  code: {
    fontFamily:'ShareTechMono'
  },
  headerimage: {
    width: 200,
    height: 34,
    borderWidth: 1
  },
  loadMoreBtn: {
    padding: 10,
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily:'ShareTechMono'
  }
};


export default App;